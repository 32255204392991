@import url("https://fonts.googleapis.com/css?family=Inter&display=swap");

.updateCardContainer {
    position: fixed;
    width: 100vw;
    height: 110vh;
    background-color: rgba(0,0,0,0.5);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
}

.updateCard {
    background-color: var(--primaryColorDarker);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 10px;
    overflow-y: scroll;
    max-height: 600px;
    margin-inline: 20px;
    border: 2px solid var(--underLine);
}

.dismissButton {
    width: 100%;
    height: 40px;
    border: none;
    background-color: rgba(0,80,255,1);
    color: #fff;
    font-size: 1.25rem;
    font-family: "Inter";
    border-radius: 8px;
    transition: 0.2s;
    cursor: pointer;
    font-weight: 600;
    letter-spacing: -0.6px;
}

.dismissButton:hover {
    background-color: #000;
    color: #fff;
}

.updateCardTitleContainerContainer {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 40px;
}

.updateCardTitleContainerContainer > h2 {
    font-family: "Inter";
    font-size: 1.5rem;
}

.updateItemsContainer {
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 20px;
}

.updateCardTitleContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.updateItem {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
}

.updateItemNumber {
    margin-right: 10px;
    font-size: 1.25rem;
    font-weight: 600;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.updateItemText {
    font-family: "Inter";
    font-size: 1.25rem;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
}

.updateCardDate {
    height: 100%;
    align-self: center;
}

.updateCardTitle {
    font-size: 1.4em;
    font-family: "Inter";
    font-weight: 600;
}

.updateImage {
    width: 50%;
}