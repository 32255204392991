@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400&family=Montserrat:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&display=swap');

.logo-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    align-items: center;
    justify-content: center;
}

.logo-text {
    font-size: clamp(1rem, 1.2rem, 1.5rem);
    color: var(--secondary-color);
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    /* background-color: #aaa; */
    margin-left: 10px;
    /* font-family: "Comfortaa"; */
    font-weight: 550;
    letter-spacing: -0.6px;
}

.logo-text::after {
    content: attr(version);
    color: rgba(0, 80, 255, 0.8);
    height: 100%;
    font-size: 0.8rem;
    text-align: bottom;
    align-self: flex-end;
    padding-left: 2px;
}

.logo-svg {
    fill: rgba(0, 80, 255, 1);
    /* height: 100%; */
    height: 34px;
    width: 28px;
    filter: drop-shadow(0px 0px 10px rgba(0, 80, 255, 0.8));
    transform: rotate(-90deg);
    /* background-color: #000; */
}

@media (max-width: 375px) {
    .logo-text {
        font-size: clamp(1rem, 1.1rem, 1.1rem);
        margin-left: 0px;
    }

    .logo-svg {
        height: 36px;
        width: 36px;
    }
}