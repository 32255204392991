/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
@import url("https://fonts.googleapis.com/css?family=Raleway&display=swap"); */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat';
    /* overflow: hidden; */
    overflow-y: scroll;
    overflow-y: visible;
    overscroll-behavior: none;
}

:root {
    --primary-color: rgba(20, 20, 20, 1);
    --secondary-color: rgba(255, 255, 255, 1);
    --tertiary-color: rgba(25, 25, 25, 1);
    --quaternary-color: rgba(40, 40, 50, 1);
    --quinary-color: rgba(10, 10, 20, 1);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

body,
#root,
html {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--primary-color);
    /* background-color: #ddd; */
}

.page-container {
    width: 100%;
    /* height: 100%; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: radial-gradient(ellipse 80% 50% at 50% -20%, rgba(120, 119, 198, 0.3), rgba(255, 255, 255, 0));
    padding-top: 42px;
    background-color: var(--homeColor) !important;
}

.content-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    width: 100%;
    padding: 40px;
}

.question {
    /* background-color: #000; */
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.question-container {
    background-color: #000;
    width: 100vw;
    height: 100vh;
}

.question-left-bar {
    /* background: #000; */
    width: 40px;
    height: 100%;
}

.question-content {
    /* background: #000; */
    flex: 1;
    /* height: 100%; */
    padding-bottom: 20px;
}

.question-about {
    padding-bottom: 20px;
}

.question-description {
    padding-bottom: 20px;
}

.question-information {
    /* background: #000; */
    flex: 1;
    /* height: 100%; */
    padding-bottom: 20px;
}

.question-mark-scheme {
    /* background: #000; */
    flex: 1;
    /* height: 100%; */
    padding-bottom: 20px;
}

.answer-form {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.answer-textarea {
    height: 100%;
    flex: 1;
    resize: none;
    border: 1px solid var(--secondary-color);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
}

.answer-submit {
    width: 120px;
    height: 100%;
    margin-left: 20px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
}

.answer-submit-multi {
    width: 120px;
    height: 30px;
    margin-left: 20px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
}

.answer-form-multi {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.option {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
}

.option-name {
    padding-right: 20px;
}

.option-value {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    font-weight: 300;
    letter-spacing: 1px;
}

.option-checkbox {
    width: 20px;
    height: 20px;
    margin-right: 20px;
    border: none;
    background-color: rgba(220, 220, 220, 1);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.option-checkbox:active {
    background-color: #000;
}

.cross {
    width: 12px;
}

.question-marks {
    padding-bottom: 20px;
}

.question-mark-scheme {}

.home-page-container {
    display: grid;
    display: flex;
    align-items: center;
    padding-inline: 40px;
    flex-direction: column;
    padding-bottom: 0px;
    padding-top: 40px;
}

@media (max-width: 550px) {
    .home-page-container {
        padding-top: 0px;
    }

    .title-card>h3 {
        font-size: 1.25rem !important;
    }

    .footerText {
        font-size: 1.25rem !important;
    }
}

@media (max-width: 1200px) {
    .pageContent {
        width: 100%;
        flex-direction: column;
        height: auto !important;
    }

    .titleText {
        width: 100%;
        display: flex;
        align-items: center !important;
        justify-content: center !important;
        margin-top: 20px;
    }

    .title {
        display: flex;
        align-items: center !important;
        justify-content: center !important;
        text-align: center;
        margin-top: 20px !important;
        margin-left: 25px;
    }

    .heading {
        display: flex;
        align-items: center !important;
        justify-content: center !important;
        text-align: center;
        margin-left: 10px;
    }

    .graphic {
        margin-top: 40px !important;
        transform: none !important;
        margin-right: 0 !important;
        width: 100% !important;
        max-height: 400px !important;
        padding-left: 1px !important;
    }

    /* 
    .graphic::before {
        content: "Small Demo";
        width: 100%;
        font-family: "Inter";
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        font-weight: 600;
        margin-block: 20px;
        color: var(--secondaryColorLighter2);
    } */

    .pageTitle {
        align-self: center !important;
    }

    .featureTitle {
        font-size: 20px !important;
    }
}

@media (max-width: 900px) {
    .featureList {
        grid-template-columns: 1fr 1fr !important;
    }
}

@media (max-width: 700px) {
    .home-page-container {
        padding-inline: 0px !important;
    }

    .title-card {
        margin-bottom: 10px !important;
    }

    .title {
        font-size: 40px !important;
        line-height: 50px !important;
    }

    .graphicContainerContainer {
        width: auto !important;
        margin-inline: 40px !important;
    }

    .featureList {
        grid-template-columns: 1fr !important;
    }
}

@media (max-width: 600px) {
    .featureBackground {
        opacity: 1 !important;
    }

    .title-card {
        margin-bottom: 10px !important;
    }

    .title {
        width: 100% !important;
        gap: 8px !important;
        margin-right: 20px !important;
    }

    .title>span {
        padding-left: 0 !important;
    }

    .graphic {
        width: auto !important;
    }

    /* .page {
        margin-bottom: 20px !important;
    } */

    .contrast {
        margin-bottom: 0px !important;
    }

    .pageTitle {
        font-size: 40px !important;
    }
}

@media (max-width: 500px) {
    .title {
        justify-content: flex-start !important;
        margin-left: 20px !important;
    }

    .titlePart {
        font-size: 28px !important;
        line-height: 28px !important;
    }

    .bulletPoints {
        width: 100% !important;
        margin-top: 10px !important;
    }

    .bulletPoint>h3 {
        font-size: 0.8rem !important;
    }

    .bulletPoint>svg {
        height: 12px !important;
        width: 12px !important;
    }

    .ctaButton {
        width: 100% !important;
    }

    .heading {
        text-align: left;
        width: 100% !important;
        font-size: 15px !important;
        margin-left: 0px !important;
    }

    .featureList {
        grid-template-columns: 1fr !important;
    }

    .pageTitle {
        font-size: 40px;
    }

    .graphicContainerContainer {
        margin-left: 20px !important;
        margin-right: 20px !important;
    }
}

.title-card {
    width: 100%;
    padding-block: 20px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    /* border: 1px solid var(--secondary-color); */
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: var(--boxShadow1);
    background-color: var(--primary-color);
    z-index: 2;
}

.title-card>h3 {
    font-weight: 600;
    font-size: clamp(1rem, 1.5rem, 1.75rem);
    font-weight: 600;
}

.update-card {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    /* border: 1px solid var(--secondary-color); */
    flex-direction: column;
    margin-bottom: 20px;
    box-shadow: var(--boxShadow1);
    border-radius: 10px;
    /* max-height: 500px; */
    overflow-y: scroll;
    height: 500px;
    width: 100%;
    display: flex;
    align-self: center;
    flex-direction: column;
    background-color: var(--primary-color);
    z-index: 2;
}

.update-title {
    font-size: 1.6rem;
    font-weight: 600;
    position: sticky;
}

/* .update-container {
    width: 100%;
    display: flex;
    align-self: center;
    flex-direction: column;
} */

/* .update-item::before {
    content: "+ ";
    padding-left: 20px;
    font-size: 1.5rem;
    font-weight: 600;
} */

.update-item {
    display: flex;
    justify-content: flex-start;
    /* border: 1px solid var(--quaternary-color); */
    margin-bottom: 20px;
    border-radius: 10px;
    padding: 14px;
    flex-direction: column;
    box-shadow: var(--boxShadow1);
    align-content: flex-start;
    width: 100%;
    background-color: var(--primary-color);
    z-index: 2;
}

.update-item-title {
    font-size: 1.4rem;
    padding-left: 10px;
    font-size: 1.5rem;
    font-family: "Raleway";
    height: 100%;
    display: flex;
    align-items: center;
}

.update-item-text {
    font-size: 1.2rem;
    font-family: "Raleway";
    margin-bottom: 10px;
    display: flex;
}

.progress-container {
    width: 100%;
    /* border: 1px solid var(--secondary-color); */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    box-shadow: var(--boxShadow1);
    border-radius: 20px;
    /* padding: 40px; */
    /* padding-top: 20px; */
}

.progress-item {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logoContainer {
    height: 100%;
    /* background-color: #aaa; */
    flex: 2;
    font-weight: 600;
    /* font-size: 1.5rem; */
    display: flex;
    align-items: flex-start;
    justify-content: center;
    font-weight: 600;
    font-size: 1.2rem;
    /* background-color: #aaa; */
    padding-top: 14px;
}

.progressItemTexts {
    flex: 4;
    height: 100%;
    /* background-color: #ccc; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-block: 10px;
}

.progress-item-text {
    flex: 1;
    height: 100%;
    /* background-color: #ccc; */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
}

.progressItemText {
    /* background-color: #aaa; */
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    font-size: 1rem;
}

.levelContainer {
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--primary-color);
    border-radius: 10px;
}

.levelName {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 2.5rem;
    margin-bottom: 20px;
    font-family: "Raleway";
    background-color: rgba(0, 60, 255, 1);
    z-index: 10;
    color: #fff;
    border-radius: 10px;
}

.examBoardName {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 2rem;
    border-bottom: 1px solid var(--secondary-color);
    font-family: "Raleway";
    background-color: var(--secondary-color);
    color: var(--primary-color);
    /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
}

.levelContentContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    /* grid-template-columns: 1fr 1fr; */
    width: 100%;
    flex-direction: column;
    background-color: var(--primary-color);
}

.examBoardContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid var(--secondary-color); */
    background-color: var(--primary-color);
}

.examBoardContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid var(--secondary-color); */
    flex-direction: column;
    box-shadow: var(--boxShadow1);
    /* margin-bottom: 20px; */
    /* border-radius: 20px; */
    background-color: var(--primary-color);
}

.examBoardContentContainer {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    grid-auto-rows: 140px;
}

.subjectContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.subscribeButtonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: var(--secondary-color);
    height: 60px;
    margin-bottom: 20px;
}

.subscribeButton {
    width: 100%;
    height: 100%;
    background-color: transparent;
    font-size: 2rem;
    font-weight: bold;
    font-family: Montserrat;
    color: var(--primary-color);
    cursor: pointer;
    border: none;
}

.subscribeButton:hover {
    background-color: rgba(0, 60, 255, 1);
}

.footer {
    width: 100vw;
    height: 100px;
    background-color: var(--secondary-color);
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footerText {
    color: var(--primary-color);
    font-weight: 600;
    font-family: "Raleway";
    font-size: 1.5rem;
}

.temporaryContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

@media (max-width: 1025px) {
    .temporaryContainer {
        flex-direction: column;
    }

    .future-card {
        height: auto !important;
        margin: 0px !important;
        margin-bottom: 10px !important;
    }

    .update-card {
        height: auto;
    }

    .update-item:not(:first-child) {
        display: none;
    }

    .control-card {
        display: none !important;
    }

    .examBoardContentContainer {
        grid-template-columns: 1fr;
    }

    .logoContainer {
        font-size: 1rem;
    }

    /* .page {
        margin-bottom: 40px !important;
    } */
}

.control-card {
    width: 100%;
    box-shadow: var(--boxShadow1);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 20px;
    border-radius: 6px;
    background-color: var(--primary-color);
    z-index: 2;
}

.shortcut-key-text {
    padding-left: 40px;
    font-family: "Raleway";
    font-size: clamp(0.75rem, 1.3rem, 1.3rem);
    flex: 1;
}

.future-card {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    /* border: 1px solid var(--secondary-color); */
    flex-direction: column;
    padding: 10px;
    margin-bottom: 20px;
    box-shadow: var(--boxShadow1);
    border-radius: 10px;
    padding: 20px;
    /* max-height: 500px; */
    overflow-y: scroll;
    height: 500px;
    background-color: var(--primary-color);
    z-index: 2;
}

.future-title {
    font-size: clamp(0.9rem, 1.4rem, 1.4rem);
    position: sticky;
    font-weight: 600;
    font-family: "Raleway";
}

.future-container {
    width: 100%;
    display: flex;
    align-self: center;
    padding-top: 20px;
    flex-direction: column;
    justify-content: flex-start;
}

.future-container>h3::before {
    content: "+ ";
    font-size: 2rem;
    padding-right: 20px;
}

.update-item-title-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.update-item-text-container {
    display: flex;
    align-items: flex-start;
    padding-left: 20px;
}

.update-item-text-index {
    padding-right: 10px;
    font-weight: 600;
    font-size: 1.2rem;
    padding-top: 1px;
}

.update-item-version {
    font-size: 1.4rem;
    padding-left: 6px;
    font-weight: 600;
    font-family: "Raleway";
}

.update-item-title-start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
}


.update-item-title-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.future-logo {
    width: 34px;
    height: 34px;
    fill: rgba(0, 80, 255, 1);
    margin-right: 10px;
}

.future-title-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.future-item-text {
    width: 100%;
    font-family: "Raleway";
    flex: 1;
    margin-left: 10px;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
}

.page {
    width: 100%;
    min-height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 160px;
}

.ellipse {
    /* background: radial-gradient(ellipse 40% 20% at 25% 25%, rgba(100, 80, 200, 0.04), rgba(40, 255, 255, 0)); */
}

.title {
    font-size: 46px;
    line-height: 60px;
    width: 600px;
    /* text-align: center; */
    font-family: "Inter";
    font-weight: 500;
    letter-spacing: -1.6px;
    margin-top: 100px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
}

.titlePart {
    font-size: 46px;
    line-height: 60px;
    font-family: "Inter";
    font-weight: 500;
    letter-spacing: -1.6px;
    word-wrap: break-word;
}

.heading {
    font-size: 20px;
    width: 500px;
    font-weight: 400;
    font-family: "Inter";
}

.titleText {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding-inline: 20px;
}

.graphicContainerContainer {
    transform: perspective(800px) rotateY(-8deg);
    height: 100%;
    width: 80%;
}

.graphic {
    box-shadow: var(--boxShadow1);
    background-color: var(--homeColor);
    margin-top: 110px;
    padding: 0.5px;
    padding-right: 1px;
    overflow: scroll;
    border-radius: 10px;
    margin-right: 30px;
    height: fit-content !important;
    justify-self: flex-start;
    align-self: flex-start;
    max-height: 100%;
    position: relative;
    background: conic-gradient(from var(--gradient-angle),
            rgba(20, 80, 255, 1),
            var(--primary-color),
            var(--primary-color),
            var(--primary-color),
            var(--primary-color),
            var(--primary-color),
            var(--primary-color),
            var(--primary-color),
            var(--primary-color),
            var(--primary-color),
            var(--primary-color),
            var(--primary-color),
            var(--primary-color),
            var(--primary-color),
            var(--primary-color),
            var(--primary-color),
            var(--primary-color),
            var(--primary-color),
            var(--primary-color),
            var(--primary-color));
    animation: rotation 5s linear infinite reverse;
    outline: 1px solid var(--underLine);
}

.ctaButton {
    width: 240px;
    height: 40px;
    background-color: rgba(0, 80, 255, 1);
    border: none;
    margin-top: 20px;
    font-family: "Inter";
    font-size: 1rem;
    letter-spacing: -0.6px;
    font-weight: 600;
    cursor: pointer;
    color: #fff;
    border-radius: 4px;
    background: linear-gradient(92.88deg, rgb(40, 80, 200) 9.16%, rgb(0, 67, 204) 43.89%, rgb(40, 50, 215) 64.72%);
    transition: box-shadow 0.25s, font-size 0.25s;
    background: linear-gradient(-var(--gradient-angle), rgba(0, 0, 255, 0.9), rgba(0, 67, 150, 1), rgba(60, 50, 200, 0.7), rgba(0, 50, 150, 0.5));
    background-size: 400% 400%;
    animation: gradient 4s ease infinite;
    border: 2px solid var(--underLine);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.ctaButton>svg {
    height: 100%;
    padding: 8px;
    fill: #fff;
    position: absolute;
    right: 0;
}

.ctaButton:hover {
    box-shadow: 0 0 5px rgb(0, 80, 181, 0.5), 0 0 10px rgb(0, 80, 181, 0.5), 0 0 15px rgb(0, 80, 181, 0.5), 0 0 20px rgb(0, 80, 181, 0.5);
    font-size: 1.1rem;
}

.contrast {
    background-color: var(--pageContrast)
}

.pageContent {
    width: 1200px;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.featureList {
    display: grid;
    width: 100%;
    /* background-color: #aaa; */
    flex: 1;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    justify-content: center;
    justify-items: center;
    row-gap: 20px;
    column-gap: 20px;
}

.feature {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    transition: all 0s;
    outline: 1px solid var(--underLine);
    /* background: radial-gradient(ellipse 100% 50% at 50% -20%, rgba(120, 119, 198, 0.1), rgba(255, 255, 255, 0)); */
    background-color: var(--homeColor);
    /* background: linear-gradient(360deg, rgba(30, 30, 0, 0), var(--primary-color), rgba(0, 80, 200, .7), rgba(0, 80, 200, .3)); */
    padding: 1px;
    transition: outline 0.25s;
}

.pageTitle {
    padding-top: 10px;
    margin-bottom: 60px;
    font-family: "Inter";
    letter-spacing: -0.6px;
    font-weight: 600;
    font-size: 46px;
    align-self: flex-start;
    letter-spacing: -1px;
    background: linear-gradient(92.88deg, rgb(0, 94, 201), rgb(50, 94, 200), rgb(80, 94, 255));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border-bottom: 2px solid var(--underLine);
    padding-bottom: 10px;
    box-shadow: var(--boxShadow1);
}

.featureTitle {
    width: 100%;
    font-family: "Inter";
    font-weight: 600;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 10px;
    text-align: center;
    height: 40px;
    font-size: 22px;
    border-bottom: 1px solid var(--underLine);
}

.featureDescription {
    width: 100%;
    font-family: "Inter";
    font-weight: 400;
    margin-bottom: 20px;
    text-align: left;
}

.bulletPoints {
    margin-top: 20px;
}

.bulletPoint {
    display: flex;
    align-items: center;
}

.bulletPoint>h3 {
    font-size: 15px;
    font-family: "Inter";
    font-weight: 400;
    color: var(--secondaryColorLighter2);
}

.bulletPoint>svg {
    height: 14px;
    width: 14px;
    fill: var(--secondaryColorLighter2);
    margin-right: 10px;
}

footer {
    width: 100vw;
    height: 100px;
    background-color: #000;
}



























/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
@import url("https://fonts.googleapis.com/css?family=Raleway&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400&family=Montserrat:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@600&display=swap'); */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* font-family: 'Montserrat'; */
    /* overflow: hidden; */
    /* overflow-y: visible; */
    color: var(--secondary-color);
    overflow-y: visible;
    /* font-family: Arial, Helvetica, sans-serif !important; */
    line-height: calc(1.25rem * 1.4);
    letter-spacing: -0.5px;
}

:root {
    --primary-color: rgba(20, 20, 20, 1);
    --secondary-color: rgba(255, 255, 255, 1);
    --tertiary-color: rgba(25, 25, 25, 1);
    --quaternary-color: rgba(40, 40, 50, 1);
    --quinary-color: rgba(10, 10, 20, 1);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

body,
#root,
html {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--primary-color);
    /* background-color: #ddd; */
}

.questions-container {
    width: 100%;
    /* height: 100%; */
    /* background-color: #aaa; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* border: 1px solid var(--secondary-color); */
    padding: 20px;
    border-radius: 20px;
    box-shadow: var(--boxShadow1);
    position: relative;
    flex: 1;
    flex-direction: column;
}

.question-container {
    width: 100%;
    /* height: 100%; */
    /* background-color: #aaa; */
    display: flex;
    /* align-items: center; */
    justify-content: center;
    /* background-color: #000; */
    /* border: 2px solid #fff; */
    border-radius: 10px;
}

.question-left-bar {
    width: 40px;
    /* height: 100%; */
    /* background-color: #ddd; */
    /* border: 1px solid #000; */
    display: flex;
    align-items: left;
    justify-content: center;
    font-weight: bold;
    font-size: 1.25rem;
    /* padding-top: 8px; */
}

.question-content {
    /* height: 100%; */
    flex: 1;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.description-structure {
    /* width: calc(100% - 40px); */
    /* min-height: 40px; */
    /* background-color: #ccc; */
    display: flex;
    justify-content: flex-start;
    /* padding-inline: 20px; */
    margin-bottom: 24px;
    margin-left: 30px;
}

h3 {
    font-family: Arial, Helvetica, sans-serif;
    font-family: "Space Grotesk";
    font-weight: 400;
    font-size: 1.25rem;
    font-family: 'Helvetica Neue';
}

.description-structure>h3 {
    font-family: Arial, Helvetica, sans-serif;
    font-family: "Inter";
    font-weight: 400;
    font-size: 1.25rem;
}

.description-structure>div>h3>* {
    font-size: 1.3rem;
}

.answer-input-written {
    min-height: 50px;
    width: 100%;
    margin-block: 20px;
    display: flex;
    position: relative;
    flex-direction: column;
    overflow: hidden;
}

.answer-input-written>textarea {
    width: 100%;
    /* height: 100%; */
    resize: none;
    /* padding-inline: 16px; */
    border: 0px solid var(--secondary-color);
    outline: none;
    background-color: var(--quinary-color);
    font-size: 1.1rem;
    /* vertical-align: middle; */
    /* padding-top: 15px; */
    margin-right: 20px;
    border-radius: 5px;
    line-height: 50px;
    background-color: transparent;
    font-family: "Raleway";
}

.answer-input-submit {
    width: 120px;
    height: 36px;
    /* background-color: var(--quinary-color); */
    border: none;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 20px;
    background-color: var(--buttonBgColor);
    /* border: 1px solid var(--secondary-color) */
    color: var(--buttonColor);
    border: 1px solid var(--underLine);
    align-self: flex-end;
    font-family: "Inter";
    font-weight: 400;
    letter-spacing: -0.6px;
    font-size: 100%;
    transition: background-color 0.25s;
    /* box-shadow: 0 5px 0px var(--secondaryColorDarker); */
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    transition: transform ease-in 0.1s, box-shadow ease-in 0.25s, background-color 0.25s, border 0.25s;
    /* box-shadow: var(--boxShadowButton); */
}

.answer-input-written-line {
    width: 100%;
    height: 50px;
    position: absolute;
    /* background-color: #aaa; */
    border-bottom: 1px dashed var(--secondary-color);
    pointer-events: none;
}

.answer-help {
    width: 120px;
    height: 36px;
    /* background-color: var(--quinary-color); */
    border: none;
    cursor: pointer;
    margin-right: 20px;
    border-radius: 6px;
    background-color: var(--secondary-color);
    color: var(--primary-color);
}

.answer-input-submit:hover {
    background-color: rgba(0, 80, 255, 1);
    color: #fff;
}

.answer-help:hover {
    background-color: rgba(0, 80, 255, 1);
    color: #fff;
}

.help-container {
    width: calc(100% - 40px);
    max-height: 600px;
    /* border: 1px solid var(--secondary-color); */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 20px;
    margin-block: 40px;
    /* background-color: var(--primary-color); */
    overflow-y: scroll;
    /* box-shadow: var(--boxShadow1); */
    background-color: var(--quinary-color);
    border-radius: 10px;
}

.messages-container {
    width: 100%;
    flex: 1;
}

.messages-input {
    min-height: 40px;
    width: 100%;
    display: flex;
}

.messages-input>textarea {
    width: 100%;
    resize: none;
    padding-inline: 16px;
    /* border: 0px solid var(--secondary-color); */
    outline: none;
    /* background-color: var(--quinary-color); */
    font-size: 110%;
    vertical-align: middle;
    padding-block: 10px;
    box-shadow: var(--boxShadow1);
    border: none;
    border-radius: 10px;
    background-color: var(--primary-color);
}

#user-message {
    width: 100%;
    padding: 20px;
    /* border: 1px solid rgba(0, 0, 255, 1); */
    border-radius: 10px;
    margin-bottom: 20px;
    background-color: #000;
}

#assistant-message {
    width: 100%;
    padding: 20px;
    /* border: 1px solid rgba(0, 255, 80, 1); */
    border-radius: 10px;
    margin-bottom: 20px;
    background-color: rgba(0, 0, 255, 1);
}

.message-text {
    color: #fff;
    margin-bottom: 20px;
}

.bullet-points-container {
    width: 100%;
    margin-bottom: 20px;
}

.bullet-point {
    width: 100%;
    color: var(--secondary-color);
    padding-left: 20px;
}

.bullet-point::before {
    width: 100%;
    content: "• ";
    color: var(--secondary-color);
}

.diagram {
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 20px; */
    /* background-color: #000; */
    width: 100%;
    position: relative;
}

.diagram>img {
    /* max-width: 500px; */
    padding-bottom: 20px;
    max-width: 100%;
    max-height: 100%;
}

.answerInputs {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 40px;
    height: 250px;
    /* border: 1px solid var(--secondary-color); */
}

.answerInputs>div>div>div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.multipleChoice {
    flex: 1;
    display: flex;
    align-items: center;
}

.multipleChoiceTable {
    height: 200px;
}

.column {
    height: 100%;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    border: 1px solid var(--secondary-color);
}

.row {
    width: 100%;
    height: 100%;
    flex: 1;
}

.answerInputHeaderContainer {
    height: 50px;
    display: flex;
}

.answerInputHeader {
    /* border: 1px solid var(--secondary-color); */
    height: 50px;
    width: 20vw;
    /* border-left: 1px solid var(--secondary-color); */
    border-right: 1px solid var(--secondary-color);
    border-block: 1px solid var(--secondary-color);
    font-size: 0.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    padding-inline: 10px;
}

.answerInputHeader>*>* {
    font-size: 1rem !important;
}

.answerInputChoices {
    display: flex;
    flex-direction: column;
}

.answerInputChoiceContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.answerInputChoice {
    width: 99px;
    height: 50px;
    /* border-inline: 1px solid var(--secondary-color); */
    /* border-bottom: 1px solid var(--secondary-color); */
}

.answerInputValue {
    width: 20.03vw !important;
    height: 50px;
    border-right: 1px solid var(--secondary-color);
    border-bottom: 1px solid var(--secondary-color);
    padding-inline: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.multipleChoiceValue {
    padding-block: 4px;
}

.omittedQuestion {
    width: 100%;
    display: flex;
    padding-block: 1px;
    margin-bottom: 80px;
}

.answerContainer {}

.selfMarkingButton {
    width: 120px;
    height: 40px;
    border: none;
    margin-inline: 10px;
    background-color: var(--secondary-color);
    color: var(--primary-color)
}

.markSchemeCheckList {
    /* margin-block: 20px; */
    height: 100%;
}

.markSchemeItem {
    display: flex;
    margin-bottom: 10px;
    /* border: 1px solid var(--secondary-color); */
    /* padding: 10px; */
    cursor: pointer;
    border-radius: 2px;
}

.markSchemeItemType {
    width: 40px;
    font-size: 1.25rem;
}

.markSchemeItemMark {
    width: 40px;
    font-weight: 600;
    font-size: 1.25rem;
}

.markSchemeItemContent {
    flex: 1;
    font-size: 1.25rem;
    padding-right: 20px;
}

.markSchemeItemCheckBoxContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: space-between;
}

.markSchemeItemCheckBox {
    border: 1px solid var(--secondary-color);
    height: 40px;
    width: 40px;
    padding: 4px;
    overflow: visible;
    border-radius: 2px;
}

.atom-symbol {
    position: relative;
    display: inline-flex;
    font-size: 1.1rem;
    /* background-color: #aaa; */
    height: 28px;
    align-items: center;
    /* padding-left: 20px; */
    font-weight: 600;
    /* padding-right: 6px; */
    margin-right: 6px;
    margin-left: 6px;
}

.atom-symbol-top {
    position: absolute;
    top: -5px;
    /* adjust this value to align with the subscript */
    font-size: 1rem;
    font-weight: 600;
    left: 2px;
}

.atom-symbol-bottom {
    position: absolute;
    bottom: -5px;
    /* adjust this value to align with the superscript */
    font-size: 1rem;
    font-weight: 600;
    left: 2px;
}

.atom-symbol-top-left {
    position: absolute;
    top: -10px;
    /* adjust this value to align with the subscript */
    font-size: 0.75rem;
    font-weight: 600;
    left: -10px;
}

.atom-symbol-bottom-left {
    position: absolute;
    bottom: -10px;
    /* adjust this value to align with the superscript */
    font-size: 0.75rem;
    font-weight: 600;
    left: -10px;
}

.atom-symbol-top-right {
    position: absolute;
    top: -3px;
    /* adjust this value to align with the subscript */
    right: 0;
    font-size: 0.75rem;
    font-weight: 600;
}

.atom-symbol-bottom-right {
    position: absolute;
    bottom: -3px;
    /* adjust this value to align with the superscript */
    right: 0;
    font-size: 0.75rem;
    font-weight: 600;
}

.config {
    width: 100%;
    margin-bottom: 20px;
    /* border: 1px solid var(--secondary-color); */
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4px;
    padding-inline: 0px;
    /* padding-inline: 20px; */
    border-radius: 20px;
    /* box-shadow: 0 30px 150px -0px rgb(100 100 100 / 10%),
        0 30px 160px -0px rgb(200 200 255 / 30%); */
}

.configQuestionTypes {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 100%;
}

.configQuestionType {
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.configQuestionTypeText {
    font-size: 1rem;
    flex: 1;
    padding-left: 20px;
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.configQuestionType>button {
    height: 26px;
    width: 26px;
    border: 1px dashed var(--secondary-color);
    background-color: transparent;
    border-radius: 6px;
    padding: 4px;
    cursor: pointer;
}

.configPaperFilter {
    min-height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    overflow: scroll;
    position: relative;
    /* border: 1px dashed var(--secondary-color); */
    border-collapse: collapse;
    border-radius: 10px;
    background-color: var(--primary-color);
    transition: width 0.25s, height 0.25s;
    padding-right: 0px;
    flex: 2;
    align-self: flex-start;
    z-index: 2;
}

.hideConfigPaperFilterButton {
    top: 10px;
    right: 10px;
    position: absolute;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    border-collapse: collapse;
    cursor: pointer;
}

.configPaperFilterPaper {
    display: flex;
    align-content: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    border-collapse: collapse;
    padding: 8px;
    cursor: pointer;
}

.configPaperFilterPaperText {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-left: none;
    font-size: 0.85rem;
    padding-left: 12px;
    border-collapse: collapse;
    font-family: "Raleway";
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.configPaperFilterCheckbox {
    height: 26px;
    width: 26px;
    background-color: transparent;
    border: 1px dashed var(--secondary-color);
    border-radius: 4px;
    padding: 4px;
    cursor: pointer;
}

.configSequencing {
    display: flex;
    height: 100%;
    flex: 1;
    margin-left: 20px;
}

.newQuestionButton {
    border: none;
    width: 40px;
    height: 40px;
    font-family: Arial, Helvetica, sans-serif;
    font-family: "Space Grotesk";
    border-radius: 10px;
    font-size: 1rem;
    background-color: var(--quinary-color);
    color: var(--secondary-color);
    transition: 0.1s;
    cursor: pointer;
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.newQuestionButton:hover {
    background-color: rgba(0, 80, 255, 1);
    color: #fff;
}

.newQuestionButton>svg {
    fill: var(--secondary-color);
}

.newQuestionButton:hover>svg {
    fill: #fff;
}

.popupContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.popupRelative {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.popup {
    width: 600px;
    height: 400px;
    background-color: var(--primary-color);
    box-shadow: var(--boxShadow1);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    margin-top: 100px;
    position: sticky;
    top: 100px;
}

.popupTitle {
    font-size: 1.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popupPromoTexts {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    flex: 1;
}

.popupTitle {
    width: 100%;
    font-family: "Raleway";
    font-weight: 600;
    height: 40px;
    margin-bottom: 20px;
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
}

.popupPromoText {
    width: 100%;
    font-family: "Raleway";
    font-weight: 400;
    height: 40px;
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
}

.popupPromoText::before {
    content: "+ ";
    font-weight: 600;
    font-size: 1.5rem;
    font-family: "Raleway";
}

.subscribeButton {
    width: 100%;
    height: 40px;
    background-color: var(--primary-color);
    box-shadow: var(--boxShadow1);
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1rem;
    background-color: rgba(0, 80, 255, 1);
    color: #fff;
    transition: 0.25s;
}

.subscribeButton:hover {
    background-color: var(--secondary-color);
    color: var(--primary-color);
}

.closePopupButton {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 0;
    right: 0;
}

.configExamBoardFilter {
    /* width: 160px; */
    min-height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    overflow: scroll;
    position: relative;
    /* border: 1px dashed var(--secondary-color); */
    border-collapse: collapse;
    border-radius: 10px;
    background-color: var(--primary-color);
    transition: width 0.25s, height 0.25s;
    padding-right: 30px;
    flex: 1;
    z-index: 3;
}

.configLevelFilter {
    /* width: 160px; */
    min-height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    overflow: scroll;
    position: relative;
    /* border: 1px dashed var(--secondary-color); */
    border-collapse: collapse;
    border-radius: 10px;
    background-color: var(--primary-color);
    transition: width 0.25s, height 0.25s;
    padding-right: 30px;
    flex: 1;
    z-index: 3;
}

.table-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
}

.table-structure {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--secondary-color);
    border-top: none;
    border-right: none;
}

.table-structure-column {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-collapse: collapse;
}

.table-structure-column-title {
    width: 100%;
    height: 40px;
    color: var(--secondary-color);
    font-size: 1rem;
    border-collapse: collapse;
    border-top: 1px solid var(--secondary-color);
    padding-inline: 20px;
    border-bottom: none;
    border-right: 1px solid var(--secondary-color);
    ;
    display: flex;
    align-items: center;
    justify-content: center;
}

.table-structure-row {
    width: 100%;
    height: 40px;
    border-collapse: collapse;
}

.table-structure-row-value {
    width: 100%;
    height: 40px;
    color: var(--secondary-color);
    font-size: 1rem;
    border-collapse: collapse;
    border-top: 1px solid var(--secondary-color);
    padding-inline: 20px;
    border-right: 1px solid var(--secondary-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

.lower-config {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;
}

.balance_equation {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 40px;
}

.balance_equation_input {
    width: 40px;
    border: none;
    background-color: transparent;
    font-size: 1.5rem;
    height: 100%;
    font-weight: 600;
    color: var(--secondary-color);

}

.balance_equation_input::placeholder {
    color: var(--secondary-color);
    opacity: 1;
}

.balance_equation_input:-ms-input-placeholder {
    color: var(--secondary-color);
}

.balance_equation_input::-webkit-input-placeholder {
    color: var(--secondary-color);
}

.balance_equation_text {
    border: none;
    background-color: transparent;
    font-size: 1.5rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.sourceContainer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
}

.sourceContainer>a {
    text-decoration: none;
    color: var(--secondary-color);
    font-size: 0.85rem;
    margin-right: 20px;
}

.sourceContainer>a:active {
    text-decoration: none;
    color: var(--secondary-color);
}

.sourceContainer>a:hover {
    text-decoration: none;
    color: rgba(0, 80, 255, 1);
}

.paperYearYear {
    /* width: 100%; */
    padding-left: 10px;
    font-family: "Raleway";
    /* border-bottom: 1px solid var(--secondary-color); */
    height: 40px;
    display: flex;
    align-items: center;
}

.configPaperFilterBatchSelect {
    flex: 1;
    height: 20px;
    margin-inline: 10px;
    border: 1px dashed var(--secondary-color);
    background-color: transparent;
    font-family: "Raleway";
    border-radius: 2px;
    transition: color 0.1s, background-color 0.1s;
}

.configPaperFilterBatchSelect:hover {
    background-color: var(--secondary-color);
    color: var(--primary-color);
    border: 1px dashed var(--primary-color);
}

.paperYearTitle {
    margin-bottom: 10px;
}

.batchSelectContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    border-bottom: 1px solid var(--secondary-color);
}

.paperYearContainer {
    width: 100%;
    margin-bottom: 20px;
    border-top: 1px solid var(--secondary-color);
}

.paperMonthContainer {
    width: 100%;
    padding-left: 10px;
    border-left: 1px solid var(--secondary-color);
    padding-bottom: 10px;
}

.paperMonthPaperContainer {
    padding-left: 10px;
    border-left: 1px solid var(--secondary-color);
}

.markSchemeImage {
    width: calc(100% - 40px);
    margin-right: 10px;
}

@media (min-width: 1750px) {
    .markSchemeImage {
        max-height: 500px;
        max-width: 100%;
    }

    .description-structure>div>h3>* {
        font-size: 1.4rem;
    }

    .question-left-bar {
        font-size: 1.4rem;
    }
}

@media (max-width: 1200px) {
    .graphicContainerContainer {
        max-height: 800px !important;
    }

    .graphic {
        /* height: 800px !important; */
        max-height: 800px !important;
    }

    .questionsContainer {
        width: 100% !important;
    }

    .filterConfig {
        width: 100% !important;
    }

    .featureList {
        padding-inline: 20px !important;
    }

    .config {
        width: 100%;
        margin-bottom: 20px;
        /* border: 1px solid var(--secondary-color); */
        display: grid;
        align-items: center;
        justify-content: flex-start;
        padding: 4px;
        padding-inline: 0px;
        /* padding-inline: 20px; */
        border-radius: 20px;
        /* box-shadow: 0 30px 150px -0px rgb(100 100 100 / 10%),
            0 30px 160px -0px rgb(200 200 255 / 30%); */
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        grid-auto-rows: 50px;
        height: auto;
    }

    .levelText::after {
        content: "" !important;
        padding: 0 !important;
    }

    .graphicContainerContainer {
        transform: none !important;
    }
}

@media (max-width: 700px) {
    .question-left-bar {
        width: auto;
        padding-right: 10px;
    }

    .diagram>img {
        max-width: 100% !important;
    }

    .paperMonthContainer {
        width: 100% !important;
        padding-bottom: 10px !important;
        border: none;
        padding-left: 0;
    }

    .paperMonthPaperContainer {
        border: none;
        padding-left: 0;
    }

    .paperFilterDropdown {
        flex: 3 !important;
    }
}

.answerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.markButton {
    padding-inline: 20px;
    height: 30px;
    background-color: var(--primary-color);
    border: 1px dashed var(--secondary-color);
    font-family: "Raleway";
    border-radius: 4px;
    cursor: pointer;
    color: var(--secondary-color);
    margin-right: 10px;
}

.markButton:hover {
    background-color: rgba(0, 0, 255, 1);
    color: #fff;
}

.mainContentContainer {
    display: flex;
    width: 100%;
}

.markedQuestionsContainer {
    /* height: 100%; */
    /* background-color: #aaa; */
    display: flex;
    align-items: flex-start;
    justify-content: center;
    /* border: 1px solid var(--secondary-color); */
    padding: 20px;
    border-radius: 20px;
    box-shadow: var(--boxShadow1);
    position: relative;
    flex: 1;
    margin-left: 20px;
    transition: 1s;
    overflow-y: scroll;
    flex-direction: column;
}

.markedQuestionCard {
    width: 100%;
    height: 80px;
    box-shadow: var(--boxShadow1);
    padding: 10px;
    display: flex;
}

.markedQuestionInfo {
    flex: 1;
    height: 100%;
}

.markedQuestionFunctions {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}


.markedQuestionRemoveButton {
    width: 40px;
    height: 40px;
    fill: var(--secondary-color);
    cursor: pointer;
}

.markedQuestionRemoveButton:hover {
    fill: rgba(255, 0, 70, 1);
}

.universalSelect {
    width: 100%;
    display: flex;
}

.aqaBox {
    border: 1px solid var(--secondary-color);
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    background-color: var(--primary-color);
}

.aqaLastBox {
    display: flex;
}

.aqaDot {
    margin-inline: 4px;
}

.aqaBoxContainer {
    display: flex;
}

.answerInputMultipleTable {
    width: 100%;
    min-height: 160px;
    display: flex;
    flex-direction: column;
}

.tableRowContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.tableRow {
    display: flex;
    flex: 1;
    padding-inline: 20px;
}

.rowValue {
    min-width: 80px;
    min-height: 40px;
    border: 1px solid var(--secondary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1
}

.tableRowOption {
    font-weight: 600;
    width: 40px;
    margin-left: 10px;
}

.multipleChoiceText {
    height: 100%;
    display: flex;
    align-items: center;
    flex: 1;
    padding-right: 10px;
}

.multipleChoiceContainer {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    /* height: 30px; */
    height: auto;
    align-items: center;
    padding-left: 10px;
}

.imageDisplay {
    max-height: 100px;
    flex: 1;
}

.multipleChoiceImageContainer {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    align-items: center;
}

.bullet_point::before {
    content: "•";
    padding-right: 10px;
    font-weight: 600;
    margin-left: 10px;
}

.bullet_point {
    display: flex;
    margin-bottom: 0;
}

.questionError {
    width: 100%;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: var(--boxShadow1);
    height: 200px;
    border-radius: 10px;
    font-family: "Inter";
    letter-spacing: -1px;
}

.filterConfig {
    width: 100%;
    width: 1200px;
    height: 36px;
    display: flex;
    /* align-items: space-between; */
    /* justify-content: space-between; */
    /* background-color: #aaa; */
    z-index: 2;
    margin-bottom: 20px;
}

.questionTypeFilterDropdown {
    flex: 1;
    height: 100%;
    background-color: var(--primary-color);
    box-shadow: var(--boxShadow1);
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    z-index: 2;
    margin-right: 20px;
    height: 100%;
}

.questionType {
    display: flex;
    position: relative;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-inline: 10px;
    height: 100%;
    user-select: none;
}

.questionTypeText {
    font-family: "Space Grotesk";
    letter-spacing: -0.4px;
    border-radius: 10px;
    font-size: 1rem;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
}

.questionType>svg {
    width: 16px;
    fill: var(--secondary-color);
}

.questionTypesDropdown {
    position: absolute;
    top: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
    box-shadow: var(--boxShadow1);
    border-radius: 12px;
    background-color: var(--primary-color);
    /* padding-inline: 2px; */
}

.questionTypeButton {
    width: 100%;
    border: none;
    cursor: pointer;
    font-family: "Space Grotesk";
    background-color: transparent;
    font-size: 1rem;
    color: var(--secondary-color);
    height: 32px;
    margin-block: 2px;
    border-radius: 7px;
    transition: background-color 0.1s, color 0.1s;
    user-select: none;
}

.questionTypeButton:hover {
    background-color: rgba(0, 80, 255, 1);
    color: #fff;
}

.paperFilterDropdown {
    flex: 2;
    height: 100%;
    background-color: var(--primary-color);
    box-shadow: var(--boxShadow1);
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    z-index: 2;
    margin-right: 20px;
}

.yearContainer {
    width: 100%;
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: space-between;
}

.yearText {
    font-size: 1.4rem;
    font-family: "Roboto Mono";
    flex: 1;
}

.monthText {
    font-size: 1.2rem;
    flex: 1;
    font-weight: 600;
    font-family: "Roboto Mono";
}

.paperCount {
    font-size: 0.8rem;
    font-family: "Space Grotesk";
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.paperFilterPapers {
    position: absolute;
    top: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
    box-shadow: var(--boxShadow1);
    border-radius: 8px;
    background-color: var(--primary-color);
    overflow-y: scroll;
    max-height: 500px;
}

.selectAllPapersButton {
    border: none;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.1s;
    width: 120px;
    height: 24px;
    width: 120px;
}

.selectAllPapersButton:hover {
    background-color: rgba(0, 80, 255, 1);
    color: #fff;
}

.paperSelection {
    display: flex;
}

.paperSelectionText {
    flex: 1;
    display: flex;
}

.paperSelectionText>h3 {
    font-family: "Roboto Mono";
    margin-right: 8px;
    font-size: 1rem;
}

.paperSelectionBox {
    width: 28px;
    height: 28px;
    border: 1px solid var(--secondary-color);
    cursor: pointer;
    background-color: transparent;
    border-radius: 2px;
}

.paperUniversalFunctions {
    width: 100%;
    display: flex;
    margin-bottom: 10px;
}

.paperUniversalSelectAll {
    flex: 1;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    border: none;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.1s;
}

.paperUniversalSelectAll:hover {
    background-color: rgba(0, 80, 255, 1);
    color: #fff;
}

.papersList {
    width: 100%;
    padding-inline: 10px;
}

.paperMonthFunctions {
    display: flex;
    align-items: center;
}

.questionsContainer {
    width: 100%;
    width: 1200px;
    display: flex;
    background-color: var(--primary-color);
    box-shadow: var(--boxShadow1);
    min-height: 100px;
    border-radius: 20px;
    z-index: 0;
    padding: 20px;
    flex: 1;
    position: relative;
}

.questionContainer {
    width: 100%;
    margin-bottom: 20px;
    flex: 1;
}

.questionContent {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.questionLeftBar {}

.markScheme {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.leftBarNumber {
    font-weight: 600;
    font-family: "Space Grotesk";
}

.questionQuestionContainer {
    display: flex;
    width: 100%;
}

.tick {
    stroke-dasharray: 50;
    stroke-dashoffset: 50;
    -webkit-transition: stroke-dashoffset 1s 0.5s ease-out;
    -moz-transition: stroke-dashoffset 1s 0.5s ease-out;
    -ms-transition: stroke-dashoffset 1s 0.5s ease-out;
    -o-transition: stroke-dashoffset 1s 0.5s ease-out;
    transition: stroke-dashoffset 2s 2s ease-out;
    transition: fill 1s;
    fill: 'none' !important;
    stroke: '#000';
    stroke-width: 3;
    stroke-linejoin: 'round';
    stroke-miterlimit: 1;
}

.tick.drawn {
    opacity: 1;
    stroke-dashoffset: 0;
    fill: 'none' !important;
}

.tick-box {
    width: 50px;
    height: 50px;
    border: 2px solid #000;
    overflow: hidden;
    position: relative;
}

.tick-box::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #fff;
    transition: left 0.3s ease-in-out;
}

.tick {
    fill: none;
    stroke: #000;
    stroke-width: 4;
    stroke-dasharray: 60;
    stroke-dashoffset: 60;
    transition: stroke-dashoffset 0.3s ease-in-out;
}

.tick-box.marked::before {
    left: 100%;
}

.tick-box.marked .tick {
    stroke-dashoffset: 0;
}

.noQuestions {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-family: "Space Grotesk";
    letter-spacing: 1px;
}

.levelText::after {
    content: "Level";
    padding-left: 4px;
}

.expandButton,
.fullScreenButton {
    position: absolute;
    border: none;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.expandButton>*,
.fullScreenButton>* {
    width: 20px;
    height: 20px;
    pointer-events: none !important;
}

.fullScreenButton {
    transform: rotate(45deg);
}

.expandButton {
    right: 40px;
}

.expandButton:hover {
    background-color: #aaa;
}

.wrapper:hover>.expandButton>svg>path,
.wrapper:hover>.fullScreenButton>svg>path {
    fill: rgba(0, 80, 255, 1) !important;
}

.wrapper {
    position: relative;
    height: 40px;
    width: 40px;
}

.nextQuestionButton {
    height: 36px;
    width: 36px;
    border: none;
    border-radius: 0px;
    background-color: var(--secondary-color);
    box-shadow: var(--boxShadow1);
    transition: background-color 0.15s ease-out;
    z-index: 2;
}

.nextQuestionButton>svg {
    width: 36px;
    height: 36px;
    fill: var(--primary-color);
    transition: fill 0.15s ease-out;
}

.nextQuestionButton:hover {
    background-color: rgba(0, 80, 255, 1);
}

.nextQuestionButton:hover>svg {
    fill: #fff;
}

.darkModeImage {
    mix-blend-mode: lighten;
}

.glowingTop {
    position: relative;
}

.glowingTop:after {
    position: absolute;
    content: '';
    top: 0;
    left: -50%;
    height: 2px;
    /* Adjust for the desired border thickness */
    width: 5%;
    filter: blur(1px);
    /* Adjust for the desired sharpness */
    background: conic-gradient(from var(--gradient-angle),
            rgba(20, 80, 255, 1),
            var(--primary-color),
            var(--primary-color));
    animation: rotation 1s linear infinite;
}

@keyframes animateGlow {
    0% {
        left: -100%;
    }

    100% {
        left: 100%;
    }
}

.hidden {
    opacity: 0;
    transition: all 1s;
    transform: translateX(-100%);
    filter: blur(5px);
}

.show {
    opacity: 1;
    transform: translateX(0px);
    filter: blur(0px);
}

.featureList:nth-child(2) {
    transition-delay: 200ms;
}

.featureList:nth-child(3) {
    transition-delay: 400ms;
}

.featureList:nth-child(4) {
    transition-delay: 600ms;
}

.graphicCover {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -1;
}

.graphicContainer {
    background-color: var(--homeColor);
    border-radius: 10px;
    overflow: scroll;
    padding: 20px;
}

.featureBackground {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: 5px;
    opacity: 0;
    transition: opacity 0.25s;
    background: conic-gradient(from var(--gradient-angle),
            rgba(20, 80, 255, 0.7),
            rgba(20, 70, 255, 0.4),
            var(--primary-color),
            rgba(20, 60, 255, 0.2),
            rgba(20, 80, 255, 0.4),
            rgba(20, 70, 255, 0.7));
    animation: rotation 2s linear infinite;
    pointer-events: none;
}

.feature:hover>.featureBackground {
    opacity: 1;
}

.feature:hover {
    outline: 1px solid transparent;
    background-color: transparent;
}

.feature:focus>.featureBackground {
    opacity: 1;
}

.feature:focus {
    outline: 1px solid transparent;
    background-color: transparent;
}

.featureContent {
    background-color: var(--homeColor);
    width: 100%;
    height: 100%;
    padding: 20px;
    border-radius: 5px;
    pointer-events: none;
}

@property --gradient-angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
}

@keyframes rotation {
    0% {
        --gradient-angle: 0deg;
    }

    100% {
        --gradient-angle: 360deg;
    }
}

.featureTitle>svg>path {
    fill: rgb(40, 80, 200) !important;
}

.gradient {
    background: linear-gradient(92.88deg, rgb(0, 94, 201), rgb(0, 94, 200), rgb(0, 94, 255));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.answer-input-written {
    min-height: 50px;
    width: 100%;
    margin-block: 20px;
    display: flex;
    position: relative;
    flex-direction: column;
    overflow: hidden;
}

.answer-input-written>textarea {
    width: 100%;
    /* height: 100%; */
    resize: none;
    /* padding-inline: 16px; */
    border: 0px solid var(--secondary-color);
    outline: none;
    background-color: var(--quinary-color);
    font-size: 1.1rem;
    /* vertical-align: middle; */
    /* padding-top: 15px; */
    margin-right: 20px;
    border-radius: 5px;
    line-height: 50px;
    background-color: transparent;
    font-family: "Raleway";
}

.answer-input-submit {
    width: 120px;
    height: 36px;
    /* background-color: var(--quinary-color); */
    border: none;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 20px;
    background-color: var(--buttonBgColor);
    /* border: 1px solid var(--secondary-color) */
    color: var(--buttonColor);
    border: 1px solid var(--underLine);
    align-self: flex-end;
    font-family: "Inter";
    font-weight: 400;
    letter-spacing: -0.6px;
    font-size: 100%;
    transition: background-color 0.25s;
    /* box-shadow: 0 5px 0px var(--secondaryColorDarker); */
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    transition: transform ease-in 0.1s, box-shadow ease-in 0.25s, background-color 0.25s, border 0.25s;
    /* box-shadow: var(--boxShadowButton); */
}

.answer-input-submit>svg {
    height: 100%;
    fill: var(--buttonColor);
    padding: 10px;
}

.answer-input-submit:hover>svg {
    height: 100%;
    fill: var(--secondary-color);
    padding: 10px;
}

.answer-input-submit:active {
    /* box-shadow: none; */
    /* transform: translateY(5px); */
    /* background-color: rgba(0, 0, 255, 1); */
    transform: scale(0.95);
}

.answer-input-written-line {
    width: 100%;
    height: 50px;
    position: absolute;
    /* background-color: #aaa; */
    border-bottom: 1px dashed var(--secondary-color);
    pointer-events: none;
}

.answer-help {
    width: 120px;
    height: 36px;
    /* background-color: var(--quinary-color); */
    border: none;
    cursor: pointer;
    margin-right: 20px;
    border-radius: 6px;
    background-color: var(--secondary-color);
    color: var(--primary-color);
}

.answer-input-submit:hover {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    border: 1px solid rgba(0, 80, 255, 0.1);
}


.tableRowContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.answerInputMultipleTable {
    width: 100%;
    min-height: 160px;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
}

.tableRowContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.tableRow {
    display: flex;
    flex: 1;
    padding-inline: 20px;
}

.tableRow {
    display: flex;
    flex: 1;
    padding-inline: 20px;
}

.rowValue {
    min-width: 80px;
    min-height: 40px;
    border: 1px solid var(--secondary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.tableRowOption {
    font-weight: 600;
    width: 40px;
    margin-left: 20px;
    height: 100%;
    display: flex;
}

.tableRowCheckBox {
    border: 1px solid var(--secondary-color);
    width: 36px;
    height: 36px;
    cursor: pointer;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-3px);
}

.tableRowCheckBox:hover>svg {
    width: 75%;
    height: 75%;
}

.multipleChoiceContainer:hover>.tableRowOption {
    color: rgba(0, 80, 255, 1);
}

.multipleChoiceImageContainer:hover>.tableRowOption {
    color: rgba(0, 80, 255, 1);
}

.tableRowContainer:hover>.tableRowOption {
    color: rgba(0, 80, 255, 1);
}

.tableRowCheckBox:hover>svg * {
    color: var(--secondary-color);
}

.tableRowCheckBox>svg>* {
    color: transparent;
}

.multipleChoiceText {
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex: 1;
}

.multipleChoiceContainer {
    display: flex;
    width: 100%;
    /* height: clamp(30px, 30px, 40px); */
    padding-bottom: 20px;
    align-items: center;
    cursor: pointer;
}

.imageDisplay {
    max-height: 100px;
    flex: 1;
}


.tableRowCheckBoxSelected {
    background-color: var(--buttonBgColor);
    outline: 1px solid rgba(0, 80, 255, 0.5);
    border: none;
}

.tableRowCheckBoxSelected>svg {
    width: 75%;
    height: 75%;
}

.tableRowCheckBoxSelected>svg>* {
    color: #fff !important;
}


.multipleChoiceText {
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex: 1;
}

.multipleChoiceContainer {
    display: flex;
    width: 100%;
    /* height: clamp(30px, 30px, 40px); */
    padding-bottom: 20px;
    align-items: center;
    cursor: pointer;
}

.marksCount {
    font-family: "Inter";
    display: flex;
    height: 100%;
    align-items: flex-end;
    justify-content: flex-end;
    height: fit-content;
}

.marksCount::before {
    content: "[";
    font-family: "Space Grotesk";
}

.marksCount::after {
    content: "]";
    font-family: "Space Grotesk";
}

.questionContent>svg {
    position: absolute;
    transform: translateY(3px);
    cursor: pointer;
    z-index: 1;
}

.questionContent>svg:hover>* {
    color: rgba(0, 80, 255, 1);
}