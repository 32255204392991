@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

.config {
    display: flex;
    align-items: center;
    justify-content: center;
    /* flex-direction: column; */
    z-index: 3;
    /* background-color: var(--quaternary-color); */
    height: 100%;
    margin-left: 10px;
}

.config>div {
    display: flex;
}

.selector {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 100%;
    flex-direction: row;
}

.selector>svg {
    margin-right: 10px;
    fill: var(--secondary-color);
    transition: transform 0.25s;
}

.selector-display {
    color: var(--secondary-color);
    font-size: 100%;
    user-select: none;
    -moz-user-select: none;
    -ms-flex-direction: none;
    -webkit-user-select: none;
    font-weight: 500;
    font-size: 15px;
    font-family: "Inter";
    display: block;
}

.dropdown {
    top: 30px;
    position: absolute;
    display: flex;
    flex-direction: column;
    left: -45px;
    width: 200px;
    background-color: var(--primaryColorDarker);
    border: 1px solid var(--secondaryColorLighter);
    border-radius: 6px;
    padding: 4px;
    height: 0px;
    transition: height 0.25s, opacity 0.1s ease-in;
    opacity: 0;
}

.dropdown>.dropdown-item {
    width: 100%;
    height: 0px;
    display: none !important;
    display: flex;
    /* padding-left: 10px; */
    /* border-radius: 4px; */
    cursor: pointer;
    font-family: "Inter";
    /* font-size: 16px; */
    font-weight: 400;
    transition: background-color 0.2s, color 0.2s;
    flex: 1;
}

.dropdownActive {
    height: 100px;
    opacity: 1;
}

.dropdownActive>.dropdown-item {
    height: unset !important;
    padding-left: 10px;
    border-radius: 4px;
    font-size: 16px;
    display: unset !important;
    user-select: none;
}

.dropdown-item:hover {
    background-color: rgba(0, 80, 255, 1);
}

.dropdown-item:hover {
    color: #fff;
}

.dropdown-item-display {
    color: var(--secondary-color);
    font-size: 100%;
    user-select: none;
    -moz-user-select: none;
    -ms-flex-direction: none;
    -webkit-user-select: none;
}

@media (min-width: 1200px) {}

@media (max-width: 1200px) {
    .config {
        display: none !important;
    }

    .dropdown {
        border: none;
        border-radius: 0;
        padding: 0;
        background-color: transparent;
    }

    .dropdown-item {
        border-radius: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
    }
}

.configMobile {
    width: 100%;
    margin-bottom: 10px;
}

.configMobile>.dropdown {
    width: 100%;
    position: unset;
}

.configMobile>.selector:hover {
    background-color: var(--primary-color);
}

.configMobile>.selector:hover .selector-display {
    color: var(--secondary-color);
}

.configMobile>.selector:hover svg {
    fill: var(--secondary-color);
}

.configMobile>.selector {
    height: 40px;
    justify-content: flex-start;
    padding-left: 6px;
}

.configMobile>.dropdown>.dropdown-item {
    height: 50px;
    background-color: var(--primary-color);
    justify-content: flex-start;
    margin-left: 16px;
    border-left: 2px solid var(--secondary-color);
    padding-left: 20px;
    border-radius: 0px;
}

.configContainer {
    display: flex;
    position: relative;
}