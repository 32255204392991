@import url("https://fonts.googleapis.com/css?family=Raleway&display=swap");

.loginButtonContainer {
    display: flex;
    align-items: center;
    height: 100%;
    margin-right: 10px;
}

.loginButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    width: 82px;
    height: 32px;
    background-color: rgba(0, 80, 255);
    cursor: pointer;
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    z-index: 2;
    background: linear-gradient(92.88deg, rgb(60, 80, 200) 9.16%, rgb(57, 67, 204) 43.89%, rgb(53, 63, 215) 64.72%);
    background: linear-gradient(92.88deg, rgb(40, 80, 200) 9.16%, rgb(0, 67, 204) 43.89%, rgb(20, 63, 215) 64.72%);
    color: #fff;
    padding: 0 16px;
    font-size: 1rem;
    border-radius: 16px;
    transition: box-shadow 0.25s ease-in-out;
}

.loginSvg {
    width: 22px;
    height: 22px;
    fill: #fff;
}

.loginButton:hover {
    background-color: var(--secondary-color);
    box-shadow: 0 0 5px rgb(60, 80, 181, 0.5), 0 0 10px rgb(60, 80, 181, 0.5), 0 0 15px rgb(60, 80, 181, 0.5), 0 0 20px rgb(60, 80, 181, 0.5);
}

@media (max-width: 1200px) {
    .loginButtonContainer {
        display: none;
    }
}

.loginButtonContainerMobile {
    width: 100%;
    height: 40px;
    margin-top: 20px;
}

.loginButtonContainerMobile>.loginButton {
    border-radius: 10px;
    width: 100%;
    height: 36px;
}