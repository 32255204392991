.header-nav {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 10px; */
    position: relative;
    /* padding-block: 10px; */
    padding-right: 10px;
}

.link {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-decoration: none;
    color: var(--secondary-color);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 15px;
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    width: 90px;
    font-family: "Inter";
    font-weight: 500;
    letter-spacing: -0.6px;
}

.link:active {
    text-decoration: #fff;
    color: var(--secondary-color);
}

.link:hover {
    text-decoration: none;
    color: rgb(138, 143, 152);
}

.link-highlight {
    text-decoration: none;
    color: transparent;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    background-image: linear-gradient(135deg, #43CBFF 10%, rgba(0, 80, 255, 1) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 400;
    font-size: 1.1rem;
}

.link-highlight:active {
    text-decoration: none;
    color: var(--secondary-color);
    font-weight: 400;
    font-size: 1.1rem;
}

.link-highlight:hover {
    text-decoration: none;
    color: #fff;
    background-color: rgba(0, 80, 255, 1);
    background-image: none;
    background-clip: border-box;
    -webkit-background-clip: border-box;
    -webkit-text-fill-color: #fff;
    font-weight: 400;
    font-size: 1.1rem;
}

@media (max-width: 1200px) {
    .header-nav {
        display: none;
    }
}

.header-nav-mobile {
    width: 100%;
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.header-nav-mobile > .link {
    flex: 1;
    margin-bottom: 10px;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px;
}