.blinkingCursor {
    /* Your cursor styling */
    /* border-right: 2px solid black; */
    /* Animation for blinking effect */
    animation: blink-animation 0.9s steps(5, start) infinite;
    -webkit-animation: blink-animation 0.9s steps(5, start) infinite;
    width: 0.012px !important;
    outline: 0.5px solid rgba(0, 70, 255, 1);
    height: 100%;
    display: ruby;
    letter-spacing: -100px;
    color: transparent;
}

@keyframes blink-animation {
    to {
        visibility: hidden;
    }
}

@-webkit-keyframes blink-animation {
    to {
        visibility: hidden;
    }
}

span {
    white-space: pre;
    height: min-content;
    font-family: Arial, Helvetica, sans-serif;
}

.textEditorContainer {
    /* padding: 20px; */
    width: min-content;
    /* background-color: rgba(0, 70, 255, 1); */
}

.textEditorContainer * {
    color: var(--primary-color);
    font-size: 1.25rem;
}

.textEditor {
    outline: none;
    cursor: text;
    pointer-events: all;
    user-select: none;
    display: flex;
    flex-direction: column;
    position: relative;
    width: min-content;
    min-width: 20px;
}

.textEditor * {
    pointer-events: none !important;
}

.currentNode {
}

.latexLine {
    /* border-bottom: 1px dotted var(--secondary-color); */
    display: inline-flex;
    align-items: center;
    padding-bottom: 0.2em;
    min-height: 20px;
}

.latexLine * {
    line-height: normal;
}

.selectedItem {
    background-color: rgba(0, 70, 255, 1);
    color: #fff;
    outline-color: #fff;
}

.selectedItem * {
    color: #fff;
    outline-color: #fff;
}

.neutralItem {
    background-color: transparent !important;
}