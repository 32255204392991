html body {
    display: flex;
    margin: 0;
    padding: 0;
    align-items: flex-start;
    justify-content: flex-start;
}

.navBarContainer {
    width: 1200px;
    /* background-color: #aaa; */
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    min-height: 40px;
    padding-top: 2px;
    position: absolute;
    top: 0;
    padding-inline: 20px;
    z-index: 10;
}

.navBar {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: #aaa; */
    border-bottom: 1px solid var(--underLine);
}

.dropdownButton {
    display: none;
}

@media (max-width: 1200px) {
    .dropdownButton {
        display: flex;
        height: 100%;
        transform: scale(0.75);
    }

    .navBarContainer {
        width: calc(100%) !important;
    }

    .dropdown {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}

.dropdown {
    width: 100%;
    background-color: var(--primary-color);
    box-shadow: var(--boxShadow1);
    /* border-radius: 20px; */
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    border-left: 2px solid var(--secondary-color);
    padding: 20px;
    border-radius: 4px;
}

@media (max-width: 700px) {
    .navBarContainer {
        padding-inline: 10px;
    }

    .navBar {
        padding-inline: 0;
        margin-inline: 40px;
    }
}

@media (max-width: 500px) {
    .navBarContainer {
        padding-top: 6px;
    }
}

.navBar:not(:first-child) {
    background-color: #aaa;
}

@media (max-width: 1200px) {
    .dropdown {
        display: inline;
        margin-top: 10px;
    }
}

.farRight {
    display: flex;
    height: 100%;
    padding-block: 4px;
}

.middle {
    display: flex;
    flex: 1;
    padding-inline: 10px;
}