* {
    box-sizing: border-box;
    white-space: pre;
    text-wrap: balance;
}

.aiHelperContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1em;
    padding-right: 0;
    padding-block: 0;
    padding-top: 0.75em;
    padding-left: 0.85em;
}

.messagesDisplay {
    flex: 1;
    width: 100%;
    overflow-y: scroll;
    display: flex;
    height: 100%;
    padding-bottom: auto;
    flex-direction: column-reverse;
    padding-bottom: 80px;
    scrollbar-color: var(--tertiary-color) transparent;
}

.aiTextInputContainer {
    display: flex;
    width: calc(100% - 20px);
    align-items: flex-end;
    justify-content: center;
    background-color: transparent;
    border: 1px solid var(--quaternary-color);
    border-radius: 10px;
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    padding-right: 4px;
    position: absolute;
    bottom: 10px;
    left: 10px;
    backdrop-filter: blur(10px);
    height: fit-content;
}

.aiTextInput {
    flex: 1;
    outline: none;
    resize: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0.5em;
    font-size: 1.1em;
    background-color: transparent;
    border: none;
    height: 100%;
    padding-inline: 1em;
    border-radius: 2em;
    max-height: 200px;
}

.aiSubmitButton {
    cursor: pointer;
}

.aiTextInputStatus {
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 5px;
    right: 5px;
    width: 40px;
    height: 40px;
    border-radius: 0.25em;
    background-color: var(--primary-color);
    border: 1px solid var(--quaternary-color);
    cursor: pointer;
    transition: background-color 0.25s;
    position: absolute;
}

.aiTextInputStatus:hover {
    background-color: var(--tertiary-color);
}

.userMessage {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    margin-bottom: 16px;
    overflow: visible;
}

.userMessage:hover .userMessageFunctionsContainer {
    opacity: 1;
}

.aiSpacer {
    flex-grow: 1;
    width: 100%;
    height: auto;
}

.userInfo {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.userMessageName {
    font-weight: 600;
    margin-left: -0.2em;
}

.userMessageIcon {
    position: relative;
    /* Make sure the parent is positioned */
    mask: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJsdWNpZGUgbHVjaWRlLWdyYWR1YXRpb24tY2FwIj48cGF0aCBkPSJNMjEuNDIgMTAuOTIyYTEgMSAwIDAgMC0uMDE5LTEuODM4TDEyLjgzIDUuMThhMiAyIDAgMCAwLTEuNjYgMEwyLjYgOS4wOGExIDEgMCAwIDAgMCAxLjgzMmw4LjU3IDMuOTA4YTIgMiAwIDAgMCAxLjY2IDB6Ii8+PHBhdGggZD0iTTIyIDEwdjYiLz48cGF0aCBkPSJNNiAxMi41VjE2YTYgMyAwIDAgMCAxMiAwdi0zLjUiLz48L3N2Zz4=) center / contain;
    background: rgba(0, 150, 50, 1);
    width: 20px;
    height: 20px;
}

.userMessageContent {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 0.9em;
    flex: 1;
}

.userMessageText {
    display: flex;
    padding-block: 10px;
    padding-top: 10px;
    opacity: 0.9;
    flex-wrap: wrap;
    flex: 1;
}

.aiMessage {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    margin-bottom: 16px;
    overflow: visible;
}

.aiInfo {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.aiMessageIcon {
    position: relative;
    /* Make sure the parent is positioned */
    mask: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJsdWNpZGUgbHVjaWRlLWJyYWluLWNvZyI+PGNpcmNsZSBjeD0iMTIiIGN5PSIxMiIgcj0iMyIvPjxwYXRoIGQ9Ik0xMiA0LjVhMi41IDIuNSAwIDAgMC00Ljk2LS40NiAyLjUgMi41IDAgMCAwLTEuOTggMyAyLjUgMi41IDAgMCAwLTEuMzIgNC4yNCAzIDMgMCAwIDAgLjM0IDUuNTggMi41IDIuNSAwIDAgMCAyLjk2IDMuMDhBMi41IDIuNSAwIDAgMCAxMiAxOS41YTIuNSAyLjUgMCAwIDAgNC45Ni40NCAyLjUgMi41IDAgMCAwIDIuOTYtMy4wOCAzIDMgMCAwIDAgLjM0LTUuNTggMi41IDIuNSAwIDAgMC0xLjMyLTQuMjQgMi41IDIuNSAwIDAgMC0xLjk4LTNBMi41IDIuNSAwIDAgMCAxMiA0LjUiLz48cGF0aCBkPSJtMTUuNyAxMC40LS45LjQiLz48cGF0aCBkPSJtOS4yIDEzLjItLjkuNCIvPjxwYXRoIGQ9Im0xMy42IDE1LjctLjQtLjkiLz48cGF0aCBkPSJtMTAuOCA5LjItLjQtLjkiLz48cGF0aCBkPSJtMTUuNyAxMy41LS45LS40Ii8+PHBhdGggZD0ibTkuMiAxMC45LS45LS40Ii8+PHBhdGggZD0ibTEwLjUgMTUuNy40LS45Ii8+PHBhdGggZD0ibTEzLjEgOS4yLjQtLjkiLz48L3N2Zz4=) center / contain;
    background-image: linear-gradient(55deg,
            hsl(282deg 96% 35%) 0%,
            hsl(280deg 95% 37%) 6%,
            hsl(277deg 94% 38%) 12%,
            hsl(275deg 94% 39%) 18%,
            hsl(273deg 93% 40%) 24%,
            hsl(271deg 93% 41%) 29%,
            hsl(270deg 93% 42%) 35%,
            hsl(268deg 92% 43%) 41%,
            hsl(266deg 92% 44%) 47%,
            hsl(265deg 92% 45%) 53%,
            hsl(263deg 92% 46%) 59%,
            hsl(261deg 92% 47%) 65%,
            hsl(260deg 92% 48%) 71%,
            hsl(258deg 91% 49%) 76%,
            hsl(257deg 91% 50%) 82%,
            hsl(255deg 94% 51%) 88%,
            hsl(254deg 97% 52%) 94%,
            hsl(252deg 100% 52%) 100%);
    width: 20px;
    height: 20px;
}

.aiMessageContent {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 0.9em;
    flex: 1;
}

.aiMessageText {
    display: flex;
    padding-block: 10px;
    padding-top: 10px;
    align-items: center;
    flex-wrap: wrap;
}

.aiMessageUpperContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
}

.aiMessageName {
    font-weight: 600;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.spin {
    animation: spin infinite 1s linear;
}

.aiPulse {
    width: 10px;
    height: 10px;
    animation: pulse 1s infinite;
    background-color: #aaa;
    border-radius: 50%;
    display: inline;
    margin-left: 4px;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

.aiPreview {
    opacity: 0.5;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.aiPreviewText {
    font-size: 1.5em;
    font-weight: 600;
    color: #fff;
    text-align: center;
    padding: 1em;
    opacity: 0.1;
}

.userMessageFunctionsContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    opacity: 0;
    padding-inline: 10px;
}

.aiMessageFunctionsContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    opacity: 1;
    padding-inline: 10px;
}

.messageFunction {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    opacity: 0.25;
    width: 19px;
    height: 19px;
    cursor: pointer;
    transition: opacity 0.25s;
    margin-right: 6px;
}

.messageFunction:hover {
    opacity: 0.75;
}

.userMessageEdit {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.userMessageEditTextarea {
    width: 100%;
    resize: none;
    background-color: var(--primary-color);
    border: 1px solid var(--quaternary-color);
    border-radius: 0.5em;
    padding: 0.5em;
    margin-bottom: 0.5em;
    outline: none;
    padding-right: 120px;
    margin-top: 16px;
    margin-left: 2.5em;
}

.userMessageEditSubmit {
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 10px;
    right: 10px;
    width: 32px;
    height: 32px;
    border-radius: 0.25em;
    background-color: var(--primary-color);
    border: 1px solid var(--quaternary-color);
    cursor: pointer;
    transition: background-color 0.25s;
    position: absolute;
    bottom: 12px;
    right: 8px;
}

.userMessageEditSubmit:hover {
    background-color: rgba(20, 20, 20, 1);
}

.waveformContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #aaa;
    width: 100%;
    height: 100%;
    position: absolute;

}

.waveformContainer * {
    display: inline-tables;
    align-items: center !important;
    justify-content: center !important;
}

.lookingGlass {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
}

.waveformContainer ::part(cursor) {
    height: 50px;
    top: 28px;
    border-radius: 4px;
    border: 1px solid #fff;
    width: 40px;
    background-color: transparent;
    clip-path: circle(60px at center);
}

.waveformContainer ::part(wrapper) {
    --box-size: 10px;
    background-image:
        linear-gradient(transparent calc(var(--box-size) - 1px), blue var(--box-size), transparent var(--box-size)),
        linear-gradient(90deg, transparent calc(var(--box-size) - 1px), blue var(--box-size), transparent var(--box-size));
    background-size: 100% var(--box-size), var(--box-size) 100%;
}

.waveformContainer {
    /* background-image: url('path/to/your/waveform/image.png'); */
    background-repeat: repeat-x;
    background-position: 0 0;
}

.waveformContainer ::part(cursor) {
    height: 100px;
    width: 100px;
    top: 28px;
    border-radius: 4px;
    border: 1px solid #fff;
    background-color: transparent;
}

.waveformContainer ::part(region) {
    background-color: rgba(0, 0, 0, 0.25) !important;
}

.waveformContainer ::part(region-green) {
    background-color: rgba(0, 0, 0, 0.25) !important;
    font-size: 12px;
    text-shadow: 0 0 2px #fff;
}

.waveformContainer ::part(marker) {
    background-color: rgba(0, 0, 0, 0.25) !important;
    border: 1px solid #fff;
    padding: 1px;
    text-indent: 10px;
    font-family: fantasy;
    text-decoration: underline;
}

.waveformContainer ::part(region-handle-right) {
    border-right-width: 4px !important;
    border-right-color: #f000 !important;
}