:root {
    --primary-color: rgba(20, 20, 20, 1);
    --secondary-color: rgba(255, 255, 255, 1);
    --tertiary-color: rgba(25, 25, 25, 1);
    --quaternary-color: rgba(40, 40, 50, 1);
    --quinary-color: rgba(10, 10, 20, 1);
}

@keyframes slide {
    from {
        left: 0;
    }

    to {
        left: 100%;
        transform: translateX(-100%);
    }
}

.theme-toggle-container {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    align-items: center;
    /* width: 120px; */
    border-radius: 50%;
    height: 100%;
    width: auto;
    background-color: transparent;
    position: relative;
    /* padding-block: 4px; */
    /* padding-inline: 8px; */
    cursor: pointer;
    user-select: none;
}

.theme-toggle-container>svg {
    height: 31px;
    width: 24px;
    fill: var(--secondary-color);
    transform: scale(0.75);
}

.moonSvg {
    background-color: #fff;
}

.theme-toggle-thumb {
    height: 100%;
    width: 40px;
    background-color: #000;
    position: absolute;
    right: 0;
    top: 0;
}

@media (max-width: 1200px) {
    .theme-toggle-container {
        display: none;
    }
}

.theme-toggle-container-mobile {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    align-items: center;
    /* width: 120px; */
    background-color: transparent;
    position: relative;
    /* padding-block: 4px; */
    /* padding-inline: 8px; */
    cursor: pointer;
    width: 100%;
    border-radius: 10px;
    background-color: var(--primary-color);
    box-shadow: var(--boxShadow1);
    border: 1px solid var(--secondaryColorLighter)
}

.mobileSlider {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
}

.mobileSlider > svg {
    width: 40px;
    height: 40px;
    padding: 4px;
    fill: var(--secondary-color);
}

.sliderThumb {
    position: absolute;
    height: 100%;
    width: 42px;
    background-color: var(--secondary-color);
    border-radius: 10px;
    transition: 0.1s ease-in-out;
    left: 0;
}