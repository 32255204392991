@import url('https://cdn.jsdelivr.net/gh/aaaakshat/cm-web-fonts@latest/fonts.css');

span {
    line-height: normal;
}

.sigmaSymbol {
    font-size: 2.5em;
}

.sigmaContainer {
    display: "inline-flex";
    margin-inline: 0.4em;
}

.preContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.upperLimit, .lowerLimit {
    font-size: 0.75em;
}

.upperLimit {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom: 0.1em;
}

.lowerLimit {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 0.1em;
}

.funcContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sigmaExpression {
    display: flex;
    align-items: center;
    justify-content: center;
}

span {
    text-wrap: nowrap;
    color: #fff;
}

.inputForm {
    display: block;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 40px;
    min-width: 120px;
}

.inputInput {
    width: 100%;
    border: none;
    resize: none;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--primary-color);
}

.inputLine {
    border-bottom: 1px dashed var(--secondary-color);
    width: 100%;
    height: 30px;
}

.root::after {
    content: 'test';
    width: 20px;
    height: 20px;
    display: inline-block;
}

/* .root {
    position: absolute;
    left: 0;
    top: 0;;
} */

.fractionParent {
    width: 14px;
    height: 28px;
    display: flex;
    flex-direction: column;
};

.ab {
    height: 14px;
    width: 14px;
    border: 1px solid #000;
}

.bc {
    height: 14px;
    width: 14px;
    border: 1px solid #000;
}

/* integral */

.integralGreaterLimitsContainer {
    display: flex;
    /* background-color: orange; */
}

.integralContainer {
    display: inline-flex;
    flex-direction: row !important;
}

.integralDisplayLimitsContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-Width: 30px;
}

.integralScalable {
    display: block;
    overflow: hidden !important;
    z-index: 0;
    margin-top: -0.05em;
    margin-bottom: -0.025em;
}

.scalableContent {
    width: 100%;
    transform: scaleY(100);
}

.intMiddle {
    display: flex;
    height: 100%;
}

.integralUpperLimit {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 0.75em;;
}

.integralLowerLimit {
    font-family: Arial, Helvetica, sans-serif;
    transform: translateX(-0.75em); /* need a better way of doing this */
    font-size: 0.75em;;
}

.integralLimitsContainer {
    /* background-color: green; */
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
}

.integralFunctionContainer {
    display: flex;
    /* background-color: #aaa; */
    flex: 1;
    height: 100px;
    align-items: center;
    justify-content: flex-start;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.25em;
}

.blinkingCursor {
    /* Your cursor styling */
    /* border-right: 2px solid black; */
    /* Animation for blinking effect */
    animation: blink-animation 0.9s steps(5, start) infinite;
    -webkit-animation: blink-animation 0.9s steps(5, start) infinite;
    width: 0.012px !important;
    outline: 0.5px solid rgba(0, 70, 255, 1);
    height: 100%;
    display: ruby;
    letter-spacing: -100px;
    color: transparent;
}