* {
    box-sizing: border-box;
    white-space: pre;
}

.pageContainer {
    width: 100%;
    /* padding-inline: 20px; */
    background-color: var(--primary-color);
    position: relative;
    overflow-y: scroll;
    display: flex;
}

.coursesContainer {
    padding: 1em;
    width: 100%;
}

.courseContainer {
    display: flex;
    flex-direction: column;
}

.courseTitle {
    margin-bottom: 0.5em;
}

.courseSubContainer {
    display: flex;
    justify-content: space-between;
    margin-left: 2.5em;
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
    border-bottom: 1px solid var(--secondary-color);
}

.courseStartButton {
    width: 20em;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    border: none;
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 0.25em;
    cursor: pointer;
}

.courseStartButton:hover {
    background-color: rgba(0, 70, 255, 1);
}