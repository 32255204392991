* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    white-space: pre;
}

.pageContainer {
    width: 100%;
    height: 100%;
    /* padding-inline: 20px; */
    background-color: var(--primary-color);
    position: relative;
    overflow-y: scroll;
    display: flex;
    align-items: center;
    justify-content: center;
}

.moduleContainer {
    max-width: 1200px;
    width: 100%;
    border-left: 0.5px solid var(--secondary-color);
    border-right: 0.5px solid var(--secondary-color);
    display: flex;
    height: 100%;
    overflow-y: scroll;
    padding: 2em;
    flex-direction: column;
}