@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
@import url("https://fonts.googleapis.com/css?family=Raleway&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400&family=Montserrat:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@600&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* font-family: 'Montserrat'; */
    /* overflow: hidden; */
    /* overflow-y: visible; */
    color: var(--secondary-color);
    overflow-y: visible;
    /* font-family: Arial, Helvetica, sans-serif !important; */
    line-height: calc(1.25rem * 1.4);
    letter-spacing: -0.5px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

body,
#root,
html {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* background-color: #ddd; */
}

.page-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px;
    position: relative;
    margin-top: 50px;
}

.page {
    width: 1160px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
}

@media (max-width: 1200px) {
    .page-container {
        padding: 20px !important;
    }

    .page {
        width: 100% !important;
    }

    .specFilters {
        display: grid !important;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        height: 90px !important;
        row-gap: 6px;
        column-gap: 6px;
        padding: 6px;
    }

    .specFilters>* {
        width: 100% !important;
        padding: 0 !important;
        margin: 0 !important;
    }

    .arrowDropdown {
        align-items: flex-start !important;
    }

    .specFilters>*>* {
        font-size: 0.85rem !important;
    }

    .details {
        justify-content: center !important;
    }

}

@media (max-width: 800px) {
    .page-container {
        padding: 8px !important;
    }

    .specFilters>*>* {
        font-size: 0.6rem !important;
    }

    .setAllButton::after {
        content: "" !important;
    }

    .collapse>button::after {
        content: "" !important;
        margin-left: 4px;
    }

    .link {
        font-size: 1rem !important;
    }

    .year {
        font-size: 1rem !important;
    }

    .newest {
        font-size: 1rem !important;
    }

    .year::before {
        content: "" !important;
    }

    .newest::before {
        content: "" !important;
    }

    .markCount {
        display: flex !important;
        align-items: flex-start !important;
        justify-content: flex-start !important;
    }

    .point {
        padding-left: 0px !important;
        /* border-left: none !important; */
        margin-bottom: 20px !important;
    }

    .pointContainer {
        padding: 4px !important;
        border-left: none !important;
        margin-bottom: 0px !important;
    }

    .pointContent {
        padding-left: 20px !important;
        border-left: none !important;
    }

    .specContainer {
        padding-left: 0px !important;
        border-left: none !important;
        padding-top: 0px !important;
    }

    .pointPointInformation {
        display: grid !important;
        grid-template-columns: 1fr;
        grid-auto-flow: row;
        transform: translateX(0px) !important;
        width: 100% !important;
        margin-left: 10px !important;
    }

    .pointRow {
        display: flex;
    }

    .pointNumber {
        min-width: 0px !important;
        margin-right: 20px !important;
    }

    .ragRating {
        width: calc(100% - 20px) !important;
        margin-top: 20px;
    }

    .pointNumber {
        align-items: flex-start !important;
    }

    .depth1 {
        font-size: 1.4rem !important;
        letter-spacing: -0.6px;
    }

    .depth2 {
        font-size: 1.3rem !important;
    }

    .depth3 {
        font-size: 1.2rem !important;
    }

    .depth4 {
        font-size: 1.2rem !important;
    }

    .pointContainer {
        margin-left: 10px !important;
    }
}

.specFilters {
    height: 40px;
    width: 100%;
    margin-bottom: 10px;
    background-color: var(--pageContrast);
    border-radius: 4px;
    border: 1px solid var(--underLine);
    display: flex;
}

.specSearchBar {
    height: 40px;
    width: 100%;
    margin-bottom: 10px;
    position: relative;
}

.specAnalysis {
    height: 40px;
    width: 100%;
    margin-bottom: 20px;
    background-color: var(--pageContrast);
    border: 1px solid var(--underLine);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-inline: 10px;
}

.specAnalysis>h3 {
    font-family: "Space Grotesk";
    margin-right: 10px;
}

.specAnalysis>h3:nth-child(1) {
    color: rgba(255, 0, 70, 1);
}

.specAnalysis>h3:nth-child(2) {
    color: rgba(255, 191, 80, 1)
}

.specAnalysis>h3:nth-child(3) {
    color: rgba(0, 255, 80, 1);
}

.specViewer {
    width: 100%;
    flex: 1;
    box-shadow: var(--boxShadow1);
    border-radius: 6px;
    overflow-y: scroll;
    border: 1px solid var(--underLine);
}

.specViewer::-webkit-scrollbar-thumb {
    background-color: rgba(0, 80, 255, 1);
    border-radius: 20px;
    border: 3px solid transparent;
}

.specViewer::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 60, 200, 1);
}

.specSearchBar>input {
    width: 100%;
    resize: none;
    border: none;
    border-radius: 4px;
    background-color: var(--pageContrast);
    display: flex;
    padding-left: 40px;
    font-family: "Inter";
    font-size: 1.15rem;
    border: 1px solid var(--underLine)
}

.specSearchBar>svg {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    padding: 10px;
    fill: var(--secondary-color);
}

.specContainer {
    width: 100%;
    border-left: 0px solid var(--secondary-color);
    padding: 20px;
    padding-right: 0px;
    padding-block: 0px;
}

.pointContainer {
    padding-left: 20px;
}

.pointNumber {
    margin-right: 10px;
    font-size: 1.35rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pointTitle {
    font-size: 1.35rem;
    font-weight: 550;
    font-family: "Inter";
    letter-spacing: -1px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pointInformation {
    display: flex;
    position: relative;
    width: calc(100% + 10px);
    /* background-color: #aaa; */
    transition: 0.1s;
    border-radius: 6px;
    padding-block: 8px;
    padding-inline: 10px;
    transform: translateX(-10px);
    cursor: pointer;
    margin-bottom: 10px;
    ;
}

.pointInformation:hover {
    background-color: rgb(0, 80, 255, 1);
}

.pointInformation:hover>h1 {
    color: #fff;
}

.pointInformation:hover>div>svg {
    fill: #fff;
}

.point {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 30px;
    border-left: 4px solid var(--secondary-color);
    padding-left: 10px;
}

.point>* {
    color: inherit !important;
}

.point>*>* {
    color: inherit !important;
}

.point>*>*>* {
    color: inherit !important;
}

.pointPointInformation {
    display: flex;
    position: relative;
    width: calc(100% + 10px);
    /* background-color: #aaa; */
    transition: 0.1s;
    border-radius: 6px;
    padding-block: 8px;
    padding-inline: 10px;
    transform: translateX(-10px);
    cursor: pointer;
    /* margin-bottom: 10px; */
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.pointPointInformation>h1 {
    font-size: 1.25rem;
    font-weight: 500;
    font-family: "Inter";
    margin-right: 10px;
}

.pointPointInformation:hover {
    background-color: rgb(0, 80, 255, 1);
}

.pointPointInformation:hover>h1 {
    color: #fff !important;
}

.pointPointInformation:hover>div>svg {
    fill: #fff;
}

.pointContent {
    display: flex;
    flex-direction: column;
    padding-left: 64px;
    margin-top: 10px;
    transition: 1s;
    flex: 1;
}

.pointDescription {
    font-size: 1rem;
    font-family: "Inter";
    margin-bottom: 10px;
    color: var(--secondary-color);
    opacity: 0.8;
}

.studentRequirements {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.bulletPoint {
    height: 100%;
    width: 20px !important;
    font-weight: 800;
    font-size: 1.5rem;
}

.arrowDropdown {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-left: 10px;
}

.arrowDropdown>svg {
    width: 24px;
    height: 24px;
    transform: rotate(90deg);
    fill: var(--secondary-color);
}

.depth1 {
    font-size: 1.6rem;
    letter-spacing: -0.6px;
}

.depth2 {
    font-size: 1.4rem;
}

.depth3 {
    font-size: 1.35rem;
}

.depth4 {
    font-size: 1.35rem;
}

.studentRequirement {
    font-family: "Inter";
    flex: 1;
    /* word-break: break-word; */
    margin-bottom: 8px;
    padding-right: 10px;
    font-weight: 500;
    letter-spacing: -0.6px;
}

.styling {
    width: 240px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    border: 1px solid var(--underLine);
    border-radius: 6px;
    margin-block: 4px;
    margin-left: 4px;
}

.styling>button {
    flex: 1;
    border: none;
    background-color: transparent;
    font-family: "Inter";
    transition: background-color 0.25s, color 0, 25s;
    color: var(--secondary-color);
    height: 100%;
    border-radius: 6px;
    cursor: pointer;
    margin-right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.styling>button:hover {
    background-color: rgb(0, 80, 255, 1);
    color: #fff;
}

.highlight {
    color: rgb(0, 80, 255, 1) !important;
}

.dropdown {
    width: 120px;
    height: 100%;
    display: flex;
    padding: 4px;
    position: relative;
}

.dropdownSelection {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 6px;
    transition: background-color 0.2s, color 0.2s;
    border: 1px solid var(--underLine);
    position: relative;
}

.dropdownSelection:hover {
    background-color: rgb(0, 80, 255, 1);
}

.dropdownSelection:hover>h3 {
    color: #fff;
}

.dropdownSelection:hover>svg {
    fill: #fff;
}

.dropdownSelection>h3 {
    font-family: "Inter";
    font-weight: 400;
    font-size: 0.9rem;
    padding-right: 10px;
    user-select: none;
}

.dropdownSelection>svg {
    transform: rotate(90deg);
    height: 20px;
    width: 20px;
    fill: var(--secondary-color);
    position: absolute;
    right: 6px;
}

.dropdownOptions {
    position: absolute;
    width: calc(100% - 8px);
    top: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--primaryColorDarker);
    z-index: 10;
    padding: 4px;
    border-radius: 8px;
    border: 1px solid var(--underLine);
    left: 4px;
}

.dropdownOptions>button {
    z-index: 10;
    background-color: transparent;
    border: none;
    border-radius: 6px;
    width: 100%;
    cursor: pointer;
    font-family: "Inter";
    font-weight: 400;
    user-select: none;
}

.dropdownOptions>button:hover {
    background-color: rgb(0, 80, 255, 1);
    color: #fff;
}

.collapse {
    width: 240px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
}

.collapse>button {
    flex: 1;
    border: none;
    background-color: transparent;
    font-family: "Inter";
    transition: background-color 0.25s, color 0, 25s;
    color: var(--secondary-color);
    height: 100%;
    border-radius: 6px;
    cursor: pointer;
    margin-right: 4px;
    border: 1px solid var(--underLine);
    font-size: 1rem;
}

.collapse>button:hover {
    background-color: rgb(0, 80, 255, 1);
    color: #fff;
}

.nothing {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Inter";
    padding-block: 20px;
}

.details {
    display: flex;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.details>h3 {
    font-size: 1rem;
    margin-right: 10px;
    font-family: "Space Grotesk";
}

.ragRating {
    display: flex;
    width: 120px;
    height: 40px;
    margin-left: auto;
    background-color: var(--primary-color);
    border-radius: 8px;
    z-index: 10;
    pointer-events: all;
}

.ragRatingCheckBox {
    flex: 1;
    cursor: pointer;
}

.ragRatingCheckBox:hover {}

.ragRatingCheckBox>svg {
    height: 100%;
    width: 100%;
    padding: 8px;
}

.ragRatingCheckBox>svg>* {
    color: var(--primary-color) !important;
}

.ragRatingCheckBox:nth-child(1) {
    /* background-color: rgba(255, 0, 70, 1); */
    border: 1px solid rgba(255, 0, 70, 1);
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.ragRatingCheckBox:nth-child(2) {
    /* background-color: rgba(255,191,80); */
    border: 1px solid rgba(255, 191, 80, 1);
    border-left: 0;
    border-right: 0;
}

.ragRatingCheckBox:nth-child(3) {
    /* background-color: rgba(0, 255, 80); */
    border: 1px solid rgba(0, 255, 80, 1);
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.ragRatingCheckBox.selected:nth-child(1) {
    background-color: rgba(255, 0, 70, 1);
    border: 1px solid var(--secondaryColorLighter);
}

.ragRatingCheckBox.selected:nth-child(2) {
    background-color: rgba(255, 191, 80, 1);
    border: 1px solid var(--secondaryColorLighter);
}

.ragRatingCheckBox.selected:nth-child(3) {
    background-color: rgba(0, 255, 80, 1);
    border: 1px solid var(--secondaryColorLighter);
}

.ragred {
    background-color: rgba(255, 0, 70, 1);
    padding-left: 10px !important;
    border-radius: 6px !important;
}

.ragamber {
    background-color: rgba(255, 191, 80, 1);
    padding-left: 10px !important;
    border-radius: 6px !important;
}

.raggreen {
    background-color: rgba(0, 255, 80, 1);
    padding-left: 10px !important;
    border-radius: 6px !important;
}

.ragreddark {
    background-color: rgba(255, 0, 70, 0.75);
    padding-left: 10px !important;
    border-radius: 6px !important;
}

.ragamberdark {
    background-color: rgba(255, 191, 80, 0.8);
    color: #fff;
    padding-left: 10px !important;
    border-radius: 6px !important;
}

.raggreendark {
    background-color: rgba(0, 255, 80, 0.75);
    color: #fff;
    padding-left: 10px !important;
    border-radius: 6px !important;
}

.setAllButton {
    width: 120px;
    margin-inline: 10px;
    border: none;
    background-color: var(--secondary-color);
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.25s;
    font-family: "Inter";
    color: #fff;
}

.setAllButton:hover {
    box-shadow: var(--boxShadow1);
}

.setAllButton::after {
    content: "All";
    margin-left: 4px;
}

.collapse>button::after {
    content: "All";
    margin-left: 4px;
}

.year::before {
    content: "Year:";
    margin-right: 4px;
}

.newest::before {
    content: "Newest:";
    margin-right: 4px;
}

.pointRow {
    display: flex;
}

.container {
    flex-wrap: wrap;
}