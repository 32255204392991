@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
@import url("https://fonts.googleapis.com/css?family=Raleway&display=swap");

@keyframes scrollRight {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-1200px);
    }
}


@keyframes scrollLeft {
    0% {
        transform: translateX(-1200px);
    }

    100% {
        transform: translateX(0);
    }
}

.coolBackgroundLine:nth-child(even) {
    animation: scrollRight 10s linear infinite;
}

.coolBackgroundLine:nth-child(odd) {
    animation: scrollLeft 10s linear infinite;
}

.trainingCentre:hover .coolBackgroundLine {
    animation-play-state: running !important;
}

.coolBackgroundLine {
    animation-play-state: paused !important;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* font-family: 'Montserrat'; */
    /* font-family: "Raleway"; */
    /* overflow: hidden; */
    overflow-y: scroll;
    overflow-y: visible;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@property --gradient-angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
}

@keyframes rotation {
    0% {
        --gradient-angle: 0deg;
    }

    100% {
        --gradient-angle: 360deg;
    }
}

body,
#root,
html {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--primary-color);
    /* background-color: #ddd; */
}

.page-container {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background-color: var(--primary-color);
}

.trainingCentres {
    width: 100%;
    display: flex;
    height: auto;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: visible;
}

.trainingCentre {
    width: 100%;
    min-height: 260px;
    height: fit-content;
    background-color: var(--primary-color);
    box-shadow: var(--boxShadow1);
    border-radius: 20px;
    max-width: 1160px;
    padding: 10px;
    border: 2px solid var(--underLine);
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    transition: border-color 0.5s, transform 0.25s;
    margin-bottom: 1em;
}

.trainingCentre:hover {
    border: 2px solid rgba(0, 113, 227, 0.5);
    transform: scale(1.025);
}

.trainingCentreContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    margin-bottom: 20px;
}

.trainingCentreContentCover {
    display: grid;
    margin-bottom: 10px;
    grid-template-columns: 1fr 1fr;
}

.trainingCentreContentCover>* {
    height: 80px !important;
}

.trainingCentreTitle {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
    font-size: 2.5em;
    font-weight: 600;
    font-family: "Inter";
    letter-spacing: -1px;
    line-height: 1;
}

.trainingCentreConfig {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: transparent;
}

.trainingCentreStartButton {
    width: 100%;
    height: 50px;
    border: none;
    background-color: rgba(0, 90, 255, 0.5);
    color: #fff;
    font-family: "Inter";
    font-size: 1.5rem;
    border-radius: 50px;
    cursor: pointer;
    border: 2px solid rgba(0, 90, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: linear-gradient(45deg, rgba(0, 67, 150, 1), rgba(0, 122, 255, 0.9), rgba(30, 50, 255, 0.5)); */
    transition: background-color 0.25s, color 0.25s;
}

.trainingCentreStartButton:hover {
    background-color: rgba(0, 90, 255, 1);
}

.trainingCentreStartButton:hover>div {
    width: 12px;
}

.trainingCentreText {
    position: relative;
    padding-left: 24px;
    font-size: 1em;
    margin-bottom: 10px;
    z-index: 10;
}

.trainingCentreText::before {
    content: "+";
    margin-right: 10px;
    position: absolute;
    left: 0;
    top: -0.1em;
    font-size: 1.5rem;
    font-weight: 400;
    color: rgba(0, 113, 227, 1);
}

.descriptionContainer {
    width: 100%;
    margin-left: 20px;
    font-size: 1.5rem;
    font-weight: 600;
    z-index: 10;
}

.trainingCentreStartButtonBackground {
    width: 100%;
    height: 50px;
    border: none;
    color: var(--primary-color);
    font-family: "Inter";
    font-size: 1.5rem;
    border-radius: 18px;
    cursor: pointer;
    background-color: var(--primary-color);
    position: absolute;
    z-index: -1;
}

.coolBackground {
    position: absolute;
    z-index: -100;
    opacity: 0.1;
    top: -40px;
    left: 0px;
    width: 150%;
}

.coolBackgroundLine>* {
    width: 120px !important;
    height: 120px !important;
}

.coolBackgroundLine {
    width: fit-content;
}

.coolBackgroundLine {
    transition: animation-play-state 0.5s ease-out;
    animation-play-state: paused;
}

@media (max-width: 1200px) {
    .trainingCentres {
        padding-inline: 20px;
    }
}

@media (max-width: 600px) {
    .trainingCentres {
        padding-inline: 20px;
    }

    .trainingCentreTitle {
        font-size: 2.25em;
        text-align: center;
        line-height: 1.1em;
        margin-block: 10px;
        padding-left: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .trainingCentreContentCover {
        grid-template-columns: 1fr;
    }

    .trainingStatsContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center !important;
        flex-direction: column !important;
        margin-bottom: 10px;
    }

    .trainingStatLabel {
        font-size: 1.25em !important;
        width: auto !important;
    }

    .trainingStatValue {
        font-size: 1.25em !important;
        width: auto !important;
    }

    .trainingCentreText {
        position: relative;
        padding-left: 24px;
        font-size: 0.8em;
        margin-bottom: 10px;
        z-index: 10;
    }

    .descriptionContainer {
        margin-left: 10px;
    }
}


.trainingFilters {
    width: calc(100% - 40px);
    max-width: 1160px;
    height: 50px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-block: 10px;
    margin-top: 60px;
    background-color: var(--primary-color);
    box-shadow: var(--boxShadow1);
    border-radius: 20px;
    border: 1px solid var(--underLine);
    position: relative;
}

.subjectFilterDropdown {
    display: flex;
    margin: 0;
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: normal;
    position: absolute;
    top: 10px;
}

.subjectFilterDisplay {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding-left: 20px;
    cursor: pointer;
    user-select: none;
}

.subjectFilterOptions {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-top: 20px;
    overflow-y: visible;
    z-index: 10;
    background-color: var(--primary-color);
    border-radius: 10px;
    border: 1px solid var(--underLine);
    padding: 0.4em;
}

.subjectFilterOption {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: transparent;
    border: none;
    cursor: pointer;
    border-radius: 6px;
    user-select: none;
    font-size: 1.1em;
    padding: 4px;
    padding-inline: 10px;
}

.subjectFilterOption:hover {
    background-color: rgba(0, 90, 255, 0.5);
}

.subjectFilterDisplayText {
    font-size: 1.15em;
}

.subjectFilterDropdownArrow {
    margin-left: 0.5em;
    width: 24px;
    height: 24px;
}

.trainingStatsContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: column;
}

.trainingStatContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
}

.trainingStatLabel {
    font-size: 1.4em;
    font-weight: 400;
    font-family: "Inter";
    letter-spacing: -0.05em;
    text-align: center;
    color: var(--secondary-color);
    margin-left: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 50%;
}

.trainingStatValue {
    font-size: 1.4em;
    font-weight: 400;
    font-family: "Inter";
    letter-spacing: -0.05em;
    text-align: center;
    color: rgba(0, 113, 227, 1);
    margin-left: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 50%;
}

.centreSettingsButton {
    border: none;
    background-color: rgba(0, 90, 255, 0.5);
    border: 2px solid rgba(0, 90, 255, 1);
    border-radius: 50%;
    margin-left: 10px;
    padding: 6px;
    width: 50px;
    height: 50px;
    cursor: pointer;
    transition: background-color 0.25s, color 0.25s;
}

.centreSettingsButton:hover {
    background-color: rgba(0, 90, 255, 1);
}

.centreSettingsButtonCog {
    width: 100%;
    height: 100%;
}

.centreStatResetButton {
    position: absolute;
}