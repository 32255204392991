.container {
    width: 100%;
    height: 100%;
    background-color: var(--primary-color);
}

.filters {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 50px;
    background-color: var(--tertiary-color);
    padding-inline: 10px;
}

.dropdownSetting {
    width: 120px;
    height: 40px;
    border-radius: 4px;
    margin-right: 10px;
    background-color: var(--primary-color);
}

.papersList {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 20px;
    padding-block: 20px;
}

.paper {
    width: 100%;
    padding-inline: 10px;
    display: flex;
}

.paper > h2 {
    margin-right: 10px;
}

.startButton {
    margin-left: auto;
    width: 120px;
    background-color: var(--quaternary-color);
    color: var(--secondary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--tertiary-color);
    cursor: pointer;
}

.startButton:hover {
    background-color: rgba(0, 70, 255, 1);
    border-radius: 4px;
}